import { ExtractFromAPI, v1 } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useHomeData from "./useHomeData";
import useIsGuest from "./useIsGuest";

type SubscribePromoOfferResponse = ExtractFromAPI<v1.paths, "/rest/cash/promo-offer-preset/subscribe/", "post">;
type FlipCoinPromoResponse = ExtractFromAPI<v1.paths, "/rest/cash/promo-offer-preset/flip-the-coin/", "post">;

type ModalOpen<K extends string, P> = (name: K, props?: P) => void;

const useCheckPromoOffer = <K extends string, P>(open: ModalOpen<K, P>) => {
	const isGuest = useIsGuest();
	const route = useRoute();

	const { promoOffer, promoOfferSet } = route.query;

	const { refresh: refreshHomePageData } = useHomeData({ immediate: false });
	const { refresh: refreshPromotionsPageData } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			immediate: false
		}
	});

	const { execute: applyPromoOffer } = useAsyncFetch({
		path: "/rest/cash/promo-offer-preset/subscribe/",
		method: "post",
		options: { immediate: false },
		fetchOptions: () => ({
			body: { urlHash: promoOffer as string },
			onResponse: async ({ response }) => {
				const { success, presetId } = response._data as Required<SubscribePromoOfferResponse>;

				if (success) {
					if (promoOffer) {
						await navigateTo(route.path, { replace: true });
					}
					window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${presetId}/promoOffer/?outside-promo-offer=true`);
					await refreshHomePageData();
					await refreshPromotionsPageData();
					return;
				}

				window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
			}
		})
	});

	const { execute: applyFlipCoinPromoOffer } = useAsyncFetch({
		path: "/rest/cash/promo-offer-preset/flip-the-coin/",
		method: "post",
		options: { immediate: false },
		fetchOptions: {
			query: { promoOfferSet: promoOfferSet as string },
			onResponse: async ({ response }) => {
				const { success, reasonType, data } = response._data as Required<FlipCoinPromoResponse>;
				if (success) {
					if (promoOfferSet) {
						await navigateTo(route.path, { replace: true });
					}

					window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${data?.data?.promoOfferPreset?.id}/promoOffer/`);
					return;
				}

				if (reasonType === "limit_by_time") {
					open("LazyOModalFlipTheCoinUsed" as K);
					return;
				}

				if (["limit_by_promo_offers", "no_offers_found"].includes(reasonType)) {
					window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
				}
			}
		}
	});

	const callApplyPromoOffer = () => {
		const promoOfferFromLS = localStorage.getItem("promoOffer");
		if (promoOfferFromLS) {
			navigateTo({ path: route.fullPath, query: { promoOffer: promoOfferFromLS } });
			localStorage.removeItem("promoOffer");
			return;
		}

		if (!promoOffer) {
			return;
		}

		if (isGuest.value) {
			open("LazyOModalSignup" as K);
			return;
		}

		setTimeout(() => {
			applyPromoOffer();
		}, 2000);
	};

	const callApplyFlipCoinPromoOffer = () => {
		if (!promoOfferSet) {
			return;
		}
		setTimeout(() => {
			applyFlipCoinPromoOffer();
		}, 2000);
	};

	watch(
		() => isGuest.value,
		(value, oldValue) => {
			if (!value && oldValue) {
				callApplyPromoOffer();
				callApplyFlipCoinPromoOffer();
			}
		}
	);

	onMounted(() => {
		callApplyPromoOffer();
		callApplyFlipCoinPromoOffer();
	});
};

export default useCheckPromoOffer;
