import { EventData } from "~/types";

type SocketSeasonData = {
	completedLevelIndex?: number;
	levelProgressPercents?: number;
	entries?: number;
	coins?: number;
	currentLevel?: number;
};

const useSeasonsSocket = () => {
	const { add, remove } = useSockets();
	const { open } = useAppModals();
	const { exit } = useFullscreen();
	const { data: appInitData } = useAppInitData();
	const { data: seasonData } = useSeasonsData({ immediate: false });

	add("season", ({ action, data }: EventData<SocketSeasonData>) => {
		const { season } = appInitData.value || {};
		const maxLvl = seasonData.value?.levels?.length || 30;

		if (action === "progressUpdate" && season?.totalProgressPercents !== undefined) {
			season.totalProgressPercents = data?.levelProgressPercents ?? 0;

			if (data?.completedLevelIndex && data?.completedLevelIndex !== maxLvl) {
				season.currentLevel = data?.completedLevelIndex + 1;
			}
		}

		if (action === "prizeAssigned" && data?.entries) {
			exit().catch((err) => console.error(`[${err}]: Error when trying to exit full screen mode`));

			open("LazyOModalSeasonCongratulations", {
				detail: { prize: data.entries * 100, completedLevelIndex: season?.currentLevel || 1 + 1 }
			});
		}

		if (action === "availableByDeposit") {
			if (season?.isAvailable !== undefined && season?.isActive !== undefined) {
				season.isAvailable = true;
				season.isActive = true;
			}
			open("LazyOModalSeasonTower");
		}
	});

	onUnmounted(() => {
		remove("season");
	});
};

export default useSeasonsSocket;
