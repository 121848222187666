<script setup lang="ts">
import { Badge, PromoData } from "~/types";

const props = defineProps<{
	data: PromoData;
}>();

const badgeLabel = parseJSON(props.data.promoOfferPreset?.badgeLabel || "{}") as Badge;

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		offer: props.data?.promoOfferPreset?.bestDeal ? "best_deal" : "most_popular",
		location: "reward_cabinet"
	});
	window?.$store?.gaCash?.deposit?.({
		location: "reward_cabinet"
	});
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.data?.promoOfferPreset?.id}/promoOffer/`);
};
</script>

<template>
	<MRewardsWrapper image="nuxt-img/header/bonuses-promo.png" :button-text="'$ ' + data?.money" @click="handleClick">
		<template #content>
			<MPromotionBadgeOffer
				v-if="badgeLabel"
				:badgeData="badgeLabel"
				:bestDeal="data.promoOfferPreset?.bestDeal"
				:mostPopular="data.promoOfferPreset?.mostPopular"
				autosize
				textVariant="ternopil"
			/>
			<AText class="prizes text-cannes" :modifiers="['bold']" as="div">
				<MPrizeFund variant="coins" icon="coins" :iconSize="16" is-svg>
					{{ numberFormat(Number(data?.promoOfferPreset?.coins)) }}
				</MPrizeFund>
				+
				<MPrizeFund variant="entries" icon="entries" :iconSize="16" is-svg>
					{{ numberFormat(Number(data?.promoOfferPreset?.entries)) }}
				</MPrizeFund>
			</AText>
		</template>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.prizes {
	display: flex;
	align-items: center;
	gap: gutter(0.75);
	.prize {
		gap: gutter(0.5);
	}
}
</style>
