<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		value?: number;
		maxValue?: number;
	}>(),
	{
		value: 0,
		maxValue: 100
	}
);
const backgroundPosition = computed(() => `-${100 - (props.value * 100) / props.maxValue}%`);

const textValue = computed(() => `${numberFormat(props.value)}/${numberFormat(props.maxValue)}`);
</script>

<template>
	<div class="magic-boxes-progress">
		<ASvg name="entries" class="icon-entries" />
		<NuxtImg
			class="progress-base"
			src="/nuxt-img/magic-boxes/progress.png"
			loading="lazy"
			format="webp"
			alt="progress"
			width="742"
			height="176"
		/>
		<NuxtImg
			class="decor"
			src="/nuxt-img/magic-boxes/progress-decor.png"
			loading="lazy"
			format="webp"
			alt="progress decor"
			width="252"
			height="100"
		/>
		<div class="progress"><div class="progress-value" /></div>
		<AText class="text-value text-cannes" :modifiers="['semibold']" as="div">
			{{ textValue }}
		</AText>
	</div>
</template>

<style scoped lang="scss">
.magic-boxes-progress {
	position: relative;
	width: 100%;
	.icon-entries {
		position: absolute;
		z-index: 3;
		top: 0;
		left: -1px;
	}
	.progress-base {
		width: 100%;
		height: auto;
	}
	.decor {
		position: absolute;
		z-index: 3;
		top: -3%;
		left: 24%;
		width: 44%;
		height: auto;
	}
	.text-value {
		position: absolute;
		right: 10%;
		top: 24%;
		z-index: 3;
		line-height: 1;
	}
	.progress {
		overflow-x: hidden;
		position: absolute;
		z-index: 1;
		top: 17%;
		left: 2%;
		width: 89.2%;
		height: 36%;
		border-radius: 20px;
		&-value {
			width: 100%;
			height: 100%;
			transform: translateX(v-bind(backgroundPosition));
			background: url("/nuxt-img/magic-boxes/progress-bar.png") no-repeat center center / cover;
		}
	}
}
</style>
