<script setup lang="ts">
useCashGA();
useCash();
</script>

<template>
	<Teleport to="body">
		<div id="cash" />
	</Teleport>
</template>
