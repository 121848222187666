<script setup lang="ts">
const { t } = useT();
const { activeCard } = useScretchCardData();
const prepareImgUrl = useImage();

const imageBg = `url(${prepareImgUrl(`/nuxt-img/scratch-cards/rewards-bg.jpg`, {
	format: "webp",
	quility: "100"
})})`;

const { durationLeft: cardTimer, reset } = useCountdown({
	timestamp: activeCard.value?.timer ?? "",
	formatToken: "HH:mm:ss",
	isOptimized: true
});
watch(
	() => activeCard.value?.timer,
	() => {
		if (activeCard.value?.timer !== null) {
			reset(activeCard.value?.timer ?? "");
		}
	}
);
const handleGoToScratchClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "scratch_cards"
	});
	navigateTo("/scratch-cards", { external: true });
};
</script>

<template>
	<MRewardsWrapper
		class="row"
		image="/nuxt-img/scratch-cards/rewards-icon.png"
		:button-text="activeCard?.timer ? cardTimer : t('Jump in')"
		:check-time="!!activeCard?.timer"
		@click="handleGoToScratchClick"
	>
		<template #content>
			<AText class="text-cannes title" variant="toledo" :modifiers="['bold']" as="div">
				{{ t("Scratch & Win") }}
			</AText>
			<MScratchCardsProgress
				:value="activeCard?.progress?.pointsComplete"
				:maxValue="activeCard?.progress?.points"
				class="small"
			/>
		</template>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.row {
	background: v-bind(imageBg) no-repeat center center / cover;
}
.progress-wrap {
	width: 80%;
	margin-top: gutter(1);
	:deep(.icon) {
		font-size: 20px;
	}
}
</style>
