<script lang="ts" setup>
import { toast } from "vue3-toastify";

import { DetailInvite } from "@/types";

const route = useRoute();
const { open, close } = useAppModals();
const isGuest = useIsGuest();
const loginGuard = useLoginGuard();
const { data: appInitData } = useAppInitData();
const { refresh: refreshWheelOfTreasure } = useDepositStreakData({ immediate: false });
const { refresh: refreshScratchCards } = useScretchCardData({ immediate: false });
const isActiveSeason = computed(() => appInitData.value?.season?.isEnabled && !isGuest.value);
const { checkDailyOpen } = useWelcomeModal(open, close);
const { showWelcomeSeasonModal } = useSeasons();
const { open: openPackage } = usePresetPackageController();
const { t } = useT();
const { add } = useSockets();

useCheckPromoOffer(open);
useAppRefreshSocket(t);
useInitRegisterComplete();
usePresetPackageInit();

const checkSeasonModal = () => {
	loginGuard({
		success: () => {
			const today = new Date().toLocaleDateString();
			if (showWelcomeSeasonModal.value === today || !appInitData.value?.season?.isEnabled) {
				return;
			}
			open("LazyOModalSeasonWelcome", { isWelcome: true });
		}
	});
};

useInitRegisterComplete();
usePresetPackageInit();
useSeasonsSocket();

onMounted(() => {
	if (route.query.game) {
		open("LazyOModalGame");
	}

	loginGuard({
		success: () => {
			if (route.query?.openModal?.includes("daily-wheel")) {
				open("LazyOModalDailyWheel");
			}
			if (route.query?.openModal === "wheel-of-treasure") {
				open("LazyOModalWheelOfTreasureWelcome");
			}
			if (route.query?.openModal?.includes("leprechauns-gold")) {
				open("LazyOModalSeasonTower");
			}
			if (appInitData.value?.depositStreak?.isActive) {
				checkDailyOpen({ storage: "showWelcomeWheelOfTreasure", modal: "LazyOModalWheelOfTreasureWelcome" });
			}
			if (appInitData.value?.scratchCardLottery?.isActive) {
				checkDailyOpen({ storage: "showWelcomeScratchCards", modal: "LazyOModalScratchCardsWelcome" });
			}
			if (appInitData.value?.magicBox?.isActive) {
				checkDailyOpen({ storage: "showWelcomeMagicBoxes", modal: "LazyOModalMagicBoxesWelcome" });
			}
		}
	});

	if (isActiveSeason.value) {
		checkSeasonModal();
	}
});

useEvent(["nuxt:openWheelOfTreasureHowItWorksModal"], () => {
	open("LazyOModalWheelOfTreasureHowItWorks");
});
useEvent(["nuxt:openWheelOfTreasureModal"], () => {
	open("LazyOModalWheelOfTreasurePlayWheel");
});
useEvent(["nuxt:wheelOfTreasureRefresh"], () => {
	refreshWheelOfTreasure();
});
useEvent(["nuxt:openScratchCardsTicketPlayModal"], () => {
	open("LazyOModalScratchCardsTicketPlay");
});
useEvent(["nuxt:openMagicBoxesPlayModal"], () => {
	open("LazyOModalMagicBoxesSelection");
});
useEvent(["nuxt:scratchCardsRefresh"], () => {
	refreshScratchCards();
});

const showToast = (titleText: string, detail: DetailInvite) => {
	toast.success(
		`
			<div class="toast-invite-title">${titleText}</div>
			<div class="toast-invite-content">${t("You earned")}
				<span class="text-caracas bold">${detail.coins ? numberFormat(Number(detail.coins)) : ""}</span>
				<span>${detail.coins ? t("Coins") : ""}</span>
				<span class="text-chanda bold">${detail.entries ? numberFormat(Number(detail.entries)) : ""}</span>
				<span>${detail.entries ? t("Free Entries") : ""}.</span>
			</div>
		`,
		{
			toastId: detail.action,
			icon: false,
			dangerouslyHTMLString: true,
			type: toast.TYPE.DEFAULT,
			theme: toast.THEME.DARK,
			position: toast.POSITION.BOTTOM_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		}
	);
};

add("invite", (detail: DetailInvite) => {
	if (detail.action === "friend-qualified") {
		showToast(t("Your friend get qualified!"), detail);
	}
	if (detail.action === "player-qualified") {
		showToast(t("You get qualified!"), detail);
	}
});

useEvent(["nuxt:openPresetPackage"], () => {
	if (appInitData.value?.popupPresetPackages) {
		openPackage();
	}
});
</script>
<template>
	<div>
		<NuxtPage />
		<ClientOnly>
			<SupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>
<style lang="scss">
.Toastify {
	&__toast {
		overflow: visible;
		@include media-breakpoint-down(lg) {
			margin-bottom: 8px;
		}
	}
	&__toast-container {
		@include media-breakpoint-down(lg) {
			width: calc(100% - 32px);
			left: 50%;
			transform: translateX(-50%);
		}
		#friend-qualified,
		#player-qualified {
			width: 344px;
			max-width: 100%;
			margin: gutter(1) auto;

			@include media-breakpoint-down(lg) {
				width: auto;
			}
		}

		#promo-code-success {
			.Toastify__toast-icon {
				display: none;
			}
		}
	}

	.badge {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: -5px;
		left: -5px;
		width: 20px;
		height: 20px;
		border-radius: gutter(0.5);
		color: var(--chattogram);
		font-size: 12px;
		background-color: var(--caracas);
		font-weight: 500;
		z-index: 100;
	}

	.icon-goal {
		display: inline-flex;
		width: 64px;
		height: 64px;
		background-image: url(/assets/icons/64/goal.svg);
		background-position: center;
		background-repeat: no-repeat;
	}

	.icon-checked {
		background-image: url(/assets/20/checked-line.svg);
	}
}

#add {
	.icon-favorite {
		background-image: url(/assets/20/favorite-add.svg);
	}
}

#remove {
	.icon-favorite {
		background-image: url(/assets/20/favorite-remove.svg);
	}
}

#friend-qualified,
#player-qualified {
	.toast-invite-title {
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 18px;
	}
	.toast-invite-content {
		max-width: 196px;
	}
	.Toastify {
		&__close-button {
			display: block;
			opacity: 1;
		}
		&__toast-body {
			white-space: normal;
		}
	}

	.bold {
		font-weight: 600;
	}
}
</style>
