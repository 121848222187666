<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const loginGuardFunction = useLoginGuard();

defineProps<{ isShowSeason?: boolean }>();
defineEmits<{ (event: "toggleSidebar"): void }>();
const handleOpenCash = () => {
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

const handleWheelClick = () => {
	loginGuardFunction({
		fail: () => {
			open("LazyOModalSignup");
		},
		success: () => {
			open("LazyOModalDailyWheel");
		}
	});
};
</script>

<template>
	<div class="mob-navigation">
		<div class="navigation-item" @click="$emit('toggleSidebar')">
			<ASvg name="navigation/menu" class="icon" />
			<AText variant="topeka" class="text">{{ t("Menu") }}</AText>
		</div>
		<NuxtLink to="/issues/all-games/" class="navigation-item" active-class="active">
			<ASvg name="navigation/games" class="icon" />
			<AText variant="topeka" class="text">{{ t("Games") }}</AText>
		</NuxtLink>
		<div v-if="isShowSeason" class="navigation-item">
			<MLeprechaunSeasonMiniProgress class="season" />
		</div>
		<div v-else class="navigation-item play" @click="handleOpenCash">
			<div class="play-box">
				<AText variant="toulon" :modifiers="['bold', 'condensed']" class="text-cambe">+</AText>
			</div>
			<AText variant="topeka" class="text play">{{ t("Buy") }}</AText>
		</div>
		<NuxtLink to="/promotions/" class="navigation-item" active-class="active">
			<ASvg name="navigation/promo" class="icon" />
			<AText variant="topeka" class="text">{{ t("Promo") }}</AText>
		</NuxtLink>
		<div class="navigation-item" @click="handleWheelClick">
			<ASvg name="navigation/wheel" class="icon" />
			<AText variant="topeka" class="text">{{ t("Wheel") }}</AText>
		</div>
	</div>
</template>

<style scoped lang="scss">
.mob-navigation {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: gutter(1.25);
	padding: gutter(1) gutter(2);
	border-top: 1px solid var(--chandigarh);
	background: var(--craiova);
	z-index: 2;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	@include media-breakpoint-down(ld) {
		@media (orientation: landscape) {
			z-index: 2 !important;
		}
	}
}
.navigation-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40px;
	height: 40px;
	position: relative;
	color: var(--capiata);
	cursor: pointer;

	.season {
		position: absolute;
		top: -19px;
	}

	.icon {
		font-size: 24px;
		color: var(--colon);
	}

	&.active {
		color: var(--caracas);

		.icon {
			color: var(--caracas);
		}
	}

	.icon-play {
		font-size: 24px;
		color: var(--chattogram);
	}

	.play {
		margin-top: gutter(3.25);
	}

	.play-box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		top: -16px;
		width: 40px;
		min-height: 40px;
		border-radius: 999px;
		background: var(--caracas);
	}
}
</style>
