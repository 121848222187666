<script lang="ts" setup>
const { t } = useT();
const { activeCard } = useScretchCardData();
const prepareImgUrl = useImage();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/scratch-cards/sidebar.jpg`, {
	format: "webp",
	quality: "100"
})}) no-repeat left center/cover`;
</script>

<template>
	<NuxtLink to="/scratch-cards" class="scratch-sidebar">
		<div class="info">
			<AText class="text-cannes" variant="topeka" :modifiers="['bold']" as="div">
				{{ t("Scratch & Win") }}
			</AText>
			<AText class="text text-cannes" variant="texas" :modifiers="['condensed']" as="div">
				{{ t("Make purchases to fill the progress:") }}
			</AText>
			<MScratchCardsProgress
				:value="activeCard?.progress?.pointsComplete"
				:maxValue="activeCard?.progress?.points"
				class="small"
			/>
		</div>
	</NuxtLink>
</template>

<style scoped lang="scss">
.scratch-sidebar {
	display: block;
	border-radius: 10px;
	background: v-bind(imagePattern);
	padding: 8px 12px 8px 18px;
	margin-top: 16px;
	.info {
		margin-left: 60px;
	}

	.text {
		margin: 2px 0 6px;
		line-height: 12px;
	}
	:deep(.progress-wrap .icon) {
		font-size: 20px;
	}
}
</style>
