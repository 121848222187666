<script lang="ts" setup>
const { t } = useT();

const props = defineProps<{
	currentLevel?: number;
	totalProgressPercents?: number;
}>();

const valueProgress = computed(() => props?.totalProgressPercents || 0);
const currentLevel = computed(() => props?.currentLevel || 1);

const prizeIcon = computed(() => {
	if (currentLevel.value !== 1 && currentLevel.value === 30) {
		return "/nuxt-img/seasons/leprechaun/tower-prize-gold.png";
	}
	return "/nuxt-img/seasons/leprechaun/tower-prize-base.png";
});
</script>

<template>
	<div class="widget">
		<div class="season-image-wrapper">
			<div class="prize-icon">
				<NuxtImg :src="prizeIcon" format="webp" width="148" height="146" alt="prize icon" />
				<AText class="season-lvl-title" variant="toledo" :modifiers="['left', 'extrabold']" as="div">{{
					currentLevel
				}}</AText>
			</div>
		</div>

		<div class="season-wrapper">
			<div class="season-content">
				<AText class="season-title" variant="topeka" :modifiers="['left', 'bold']" as="div">{{
					t("Leprechaun's Gold")
				}}</AText>
				<AProgressLinear
					class="season-progress"
					:value="valueProgress"
					gradientId="var(--guli)"
					backgroundColor="var(--chiclayo)"
					borderColor="var(--chiclayo)"
					borderRadius="29px"
					height="13px"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.widget {
	width: 100%;
}
.season-wrapper {
	display: flex;
	background: var(--chandigarh);
	padding: gutter(1);
	display: flex;
	justify-content: flex-end;
	border-radius: 12px;
	height: 50px;
}
.season-image-wrapper {
	height: 63px;
	width: 63px;
	position: absolute;
	left: 0;
}
.season-image {
	position: relative;
}
.season-title {
	margin-bottom: gutter(0.5);
}
.season-lvl-title {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-shadow: 2px 1px rgb(0 0 0 / 83%);
}
.season-content {
	min-width: 135px;
}

.prize-icon {
	transform: translate3d(0, 0, 0);
	animation-name: shake;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	img {
		width: 63px;
		height: 63px;
	}
}

@keyframes shake {
	2%,
	18% {
		transform: translate3d(-1px, 0, 0);
	}

	4%,
	16% {
		transform: translate3d(1px, 0, 0);
	}

	6%,
	10%,
	14% {
		transform: translate3d(-1px, 0, 0);
	}

	8%,
	12% {
		transform: translate3d(1px, 0, 0);
	}

	18.1% {
		transform: translate3d(0px, 0, 0);
	}
}
</style>
