<script lang="ts" setup>
import { InitDataResponseData } from "@netgame/composables/src/useAppInitData";
import { NotificationMessagePayload } from "@netgame/composables/src/useNotificationCenter";
const { t } = useT();

const props = defineProps<{
	message: NonNullable<NonNullable<InitDataResponseData>["notificationCenter"]>["messages"][number];
}>();
const emit = defineEmits<{
	(event: "clickOnMessage", payload: NotificationMessagePayload): void;
}>();
const { durationLeft, duration } = useCountdown({
	timestamp: props.message.dateEnd,
	formatToken: "D[d] HH[h] mm[m] ss[s]"
});

const isShowTimer = computed(() => {
	if (props.message.type !== "offer") {
		return false;
	}
	const isStartLessNow = new Date(props.message.dateBegin).getTime() <= new Date().getTime();
	const isEndMoreNow = new Date(props.message.dateEnd).getTime() > new Date().getTime();
	return isStartLessNow && isEndMoreNow;
});

const handleClickMessage = () => {
	dispatchGAEvent({
		event: "click_button",
		location: props.message.isReaded ? "earlier" : "new",
		button_name: "notification",
		type: props.message.systemAlias
	});

	emit("clickOnMessage", { id: props.message.ncMessageId, needSendStatusToServer: true, shouldOpenLink: true });
};
</script>

<template>
	<div v-if="message" class="message" @click="handleClickMessage">
		<img :src="message.image?.replace('http:', 'https:')" :alt="message.title" width="40" height="40" />
		<div class="message-content">
			<AText class="text-cesena" :modifiers="['semibold']" as="div">{{ message.title }}</AText>
			<AText class="text-cairo" as="div">{{ message.message }}</AText>
			<div class="action">
				<AButton v-if="message.link" variant="primary" size="s">
					<AText>{{ message.buttonLabel || t("More") }}</AText>
				</AButton>
				<AText v-if="isShowTimer && duration" class="text-caracas" variant="topeka" :modifiers="['semibold']">
					<i18n-t keypath="{key} left">
						<template #key>
							{{ durationLeft }}
						</template>
					</i18n-t>
				</AText>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.message {
	margin-top: 4px;
	display: flex;
	justify-content: flex-start;
	transition: opacity 500ms ease-in-out;

	img,
	button {
		margin-right: 8px;
	}

	button {
		width: 104px;
	}

	.action {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: 12px;
	}
	&-content {
		flex: 1;
	}
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
