<script setup lang="ts">
const { t } = useT();
const isGuest = useIsGuest();
const { open } = useAppModals();
const { firstItemData, firstItemTimer } = useScretchCardData({ immediate: !isGuest.value });

const buttonName = computed(() => {
	if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
		return t("Play");
	}
	if (firstItemData.value?.timer) {
		return firstItemTimer.value;
	}
	return t("Buy");
});

const handleGoToScratchClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "magic_boxes"
	});
	if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
		open("LazyOModalMagicBoxesSelection");
		return;
	}
	if (firstItemData.value?.timer) {
		navigateTo("/magic-boxes", { external: true });
		return;
	}
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<MRewardsWrapper
		class="row"
		image="/nuxt-img/magic-boxes/mini-chest.png"
		:button-text="buttonName"
		:check-time="!!firstItemData?.timer"
		@click="handleGoToScratchClick"
	>
		<template #content>
			<AText class="text-cannes title" variant="toledo" :modifiers="['bold']" as="div">
				{{ t("Jungle Secrets") }}
			</AText>
			<MMagicBoxesProgress :value="firstItemData.progress?.pointsComplete" :maxValue="firstItemData.progress?.points" />
		</template>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.row > :deep(img) {
	transform: scale(1.3);
}
:deep(.magic-boxes-progress) {
	margin: 4px 0 -10px 0;
	width: 150px;
	.icon-entries {
		font-size: 28px;
	}
	.text-value {
		font-size: 10px;
	}
}
</style>
