const useSeasons = () => {
	const showWelcomeSeasonModal = useLocalStorage<null | string>("showWelcomeSeasonModal", "");
	const isSeasonSoundOn = useLocalStorage<undefined | "off" | "on">("isSeasonSoundOn", undefined);

	const toggleSound = () => {
		isSeasonSoundOn.value = isSeasonSoundOn.value === "on" ? "off" : "on";
	};

	const resetShowingSeasonWelcomeModal = () => {
		const today = new Date().toLocaleDateString();
		showWelcomeSeasonModal.value = today;
	};

	onMounted(() => {
		if (!isSeasonSoundOn.value) {
			isSeasonSoundOn.value = "on";
		}
	});

	return { isSeasonSoundOn, showWelcomeSeasonModal, toggleSound, resetShowingSeasonWelcomeModal };
};

export default useSeasons;
