<script lang="ts" setup>
import { useStorage } from "@vueuse/core";

const { t } = useT();
const { open } = useAppModals();
const { data: appInitData } = useAppInitData();
const { isMobile } = useDevice();
const { handleSupportButtonClick } = useSupportChat();
const { logoutUser } = useLogout();
const loginGuardFunction = useLoginGuard();
const { isSweepStakes } = useSwitchMode();
const referralPopupCookie = useCookie("showReferralPopup");
const dailyWheelPopupStorage = useStorage("showedDailyWheelPopup", "");

const asideRef = ref();

const props = withDefaults(
	defineProps<{
		show: boolean;
	}>(),
	{ show: false }
);

const emit = defineEmits<{ (event: "toggleSidebar"): void }>();

onClickOutside(asideRef, () => {
	if (!props.show) {
		return;
	}
	emit("toggleSidebar");
});
const isGuest = useIsGuest();

const isEmailFirstContactAndNotConfirmed = computed(
	() => appInitData.value?.primaryContact === "email" && !appInitData.value?.emailConfirmed
);
const isShowEmailTopline = computed(() => !isGuest.value && isEmailFirstContactAndNotConfirmed.value);
const isSeasonEnabled = computed(() => !isGuest.value && appInitData.value?.season?.isEnabled);
const isShowRedeem = computed(() => {
	if (appInitData.value?.redeem?.show === false) {
		return false;
	}

	if (isSweepStakes.value) {
		return appInitData.value?.redeem?.isEntries;
	}

	return appInitData.value?.redeem?.isCoins;
});

const handleOpenSeason = () => {
	open("LazyOModalSeasonTower");
};

const handleRedeemClick = () =>
	loginGuardFunction({
		success: () => {
			window?.$store?.gaCash?.deposit?.({
				location: "redeem",
				step: "payments_method"
			});
			window?.$cash?.$router?.push?.("/cash/redeem");
		}
	});

const handleWheelClick = () => {
	loginGuardFunction({
		success: () => {
			open("LazyOModalDailyWheel");
		},
		fail: () => {
			open("LazyOModalSignup", { location: "left_menu" });
		}
	});
};

const handleSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "support"
	});
	handleSupportButtonClick();
};

const handleLogoutClick = async () => {
	await logoutUser();
	navigateTo("/", { external: true });
	dispatchGAEvent({ event: "logout", location: "sidebar" });
	dailyWheelPopupStorage.value = null;
	referralPopupCookie.value = null;
	if (!props.show) {
		return;
	}
	emit("toggleSidebar");
};
</script>
<template>
	<AOverlay
		data-tid="header"
		:modifiers="['fixed']"
		:class="[{ show }, { 'is-logged': !isGuest, 'is-email-notify': isShowEmailTopline }]"
		:bg-color="isMobile ? 'var(--changshu)' : ''"
	>
		<aside ref="asideRef">
			<NuxtLink v-if="isGuest" to="/" class="logo-image" data-tid="logo">
				<NuxtImg
					src="/nuxt-img/logo-sidebar.png"
					alt="logo"
					format="webp"
					width="96"
					height="64"
					class="logo-img"
					loading="lazy"
				/>
			</NuxtLink>

			<OWheelOfTreasureSideBar v-if="appInitData?.depositStreak?.isActive && !isGuest" />

			<div class="events">
				<div class="event-item" data-tid="header-fortune-wheel" @click="handleWheelClick">
					<AText :modifiers="['semibold']">
						<span>{{ t("Wheel of Fortune") }}</span>
					</AText>
					<div class="animated-wheel">
						<NuxtImg
							src="/nuxt-img/wheel-promo/wheel-base-leaves.png"
							format="webp"
							width="450"
							height="450"
							quality="100"
							alt="leaves"
							class="leaves"
							loading="lazy"
						/>
						<AAnimationDrum>
							<NuxtImg
								src="/nuxt-img/wheel-promo/wheel-sectors.png"
								width="450"
								height="450"
								quality="100"
								format="webp"
								alt="sectors"
							/>
						</AAnimationDrum>
						<NuxtImg
							src="/nuxt-img/wheel-promo/wheel-pointers.png"
							format="webp"
							width="450"
							height="450"
							quality="100"
							alt="coins"
							class="coins"
							loading="lazy"
						/>
					</div>
				</div>
			</div>
			<div v-if="isSeasonEnabled && appInitData?.season && !isGuest" @click="handleOpenSeason">
				<OSeasonSidebarTowerLevelProgress
					:currentLevel="appInitData?.season?.currentLevel"
					:totalProgressPercents="appInitData?.season?.totalProgressPercents"
				/>
			</div>
			<OMagicBoxesSidebar v-if="!isGuest && appInitData?.magicBox?.isActive" />
			<OScratchCardsSidebar v-if="!isGuest && appInitData?.scratchCardLottery?.isActive" />
			<div v-if="isGuest" class="auth">
				<AButton
					variant="primary"
					size="lg"
					class="signup"
					data-tid="header-register-btn"
					@click="open('LazyOModalSignup', { location: 'left_menu' })"
				>
					<AText variant="toledo" :modifiers="['medium']">{{ t("Sign Up") }}</AText>
				</AButton>
				<AButton
					variant="secondary"
					size="lg"
					class="login"
					data-tid="header-login-btn"
					@click="open('LazyOModalLogin', { location: 'left_menu' })"
				>
					<AText variant="toledo" :modifiers="['medium']">{{ t("Log In") }}</AText>
				</AButton>
			</div>

			<div class="links-wrapper">
				<NuxtLink to="/issues/popular-games/" class="link" active-class="active" data-tid="header-top-games">
					<ASvg name="menu/top-games" class="link-icon" />
					<AText variant="toledo" :modifiers="['semibold']">
						{{ t("Top games") }}
					</AText>
				</NuxtLink>
				<NuxtLink v-if="!isGuest" to="/promotions/" class="link" active-class="active" data-tid="header-promotions">
					<ASvg name="menu/promotions" class="link-icon" />
					<AText variant="toledo" :modifiers="['semibold']">
						{{ t("Promotions") }}
					</AText>
					<AText variant="ternopil" class="counter"><span>1</span></AText>
				</NuxtLink>
				<NuxtLink
					v-if="!isGuest"
					to="/referral-info/"
					class="link"
					active-class="active"
					data-tid="header-referral-info"
				>
					<ASvg name="menu/referral" class="link-icon" />
					<AText variant="toledo" :modifiers="['semibold']">
						{{ t("Refer a friend") }}
					</AText>
				</NuxtLink>
				<NuxtLink v-if="!isGuest" to="/account/" class="link" active-class="active" data-tid="header-account">
					<ASvg name="menu/account" class="link-icon" />
					<AText variant="toledo" :modifiers="['semibold']">
						{{ t("Account") }}
					</AText>
				</NuxtLink>
				<NuxtLink to="/how-it-works/" class="link" active-class="active" data-tid="header-how-it-works">
					<ASvg name="menu/info" class="link-icon" />
					<AText variant="toledo" :modifiers="['semibold']">
						{{ t("How it works") }}
					</AText>
				</NuxtLink>
				<div v-if="isShowRedeem && !isGuest" class="link" data-tid="header-redeem" @click="handleRedeemClick">
					<ASvg name="menu/redeem" class="link-icon" />
					<AText variant="toledo" :modifiers="['semibold']">
						{{ t("Redeem") }}
					</AText>
				</div>
				<div class="link" data-tid="header-support" @click="handleSupportClick">
					<ASvg name="menu/support" class="link-icon" />
					<AText variant="toledo" :modifiers="['semibold']">
						{{ t("Support") }}
					</AText>
				</div>
				<div v-if="!isGuest" class="link" data-tid="header-logout" @click="handleLogoutClick">
					<ASvg name="menu/logout" class="link-icon" />
					<AText variant="toledo" :modifiers="['semibold']">
						{{ t("Logout") }}
					</AText>
				</div>
			</div>
		</aside>
	</AOverlay>
</template>

<style scoped lang="scss">
.logo-image {
	display: none;
	position: relative;

	@include media-breakpoint-up(lg) {
		display: inline-block;
		margin: 0 auto;
	}
}

.overlay {
	z-index: 100;
	width: 100%;
	display: none;

	@include media-breakpoint-up(lg) {
		display: block;
		width: var(--o-sidebar-width);
		margin: gutter(2) gutter(3) gutter(2);

		&.is-logged {
			margin-top: gutter(12);
		}

		&.is-email-notify {
			margin-top: gutter(18);
		}
	}

	&.show {
		display: block;
	}
}
aside {
	background-color: var(--crosby);
	width: var(--o-sidebar-width);
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: gutter(3);
	padding: gutter(2);
	overflow-x: hidden;
	overflow-y: auto;
	@include scrollbar(2px, 2px, var(--caracas), transparent, transparent);

	@include media-breakpoint-up(lg) {
		border-radius: 12px;
	}

	a {
		display: inline-flex;
		text-decoration: none;
	}
}

.logo-img {
	@include media-breakpoint-down(sm) {
		width: 100px;
		height: auto;
	}
}

.auth {
	border-top: 1px solid var(--chandigarh);
	border-bottom: 1px solid var(--chandigarh);
	padding: gutter(3) 0;
	display: flex;
	flex-direction: column;
	gap: gutter(1.5);
}

.events {
	display: flex;
	flex-direction: column;
	gap: gutter(1.25);
}

.event-item {
	position: relative;
	padding: gutter(1.75) gutter(1.5);
	background-color: var(--changchun);
	border-radius: 10px;
	cursor: pointer;

	.animated-wheel {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(15px, -50%);
		width: 80px;
		height: 80px;
		& img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.links-wrapper {
	display: flex;
	flex-direction: column;
}

.link {
	width: 100%;
	display: flex;
	align-items: center;
	gap: gutter(1.25);
	padding: gutter(1) gutter(1.25);
	position: relative;
	z-index: 1;
	flex: 1;
	cursor: pointer;
	transition: color 0.3s linear;
	color: var(--cannes);
	line-height: 18px;

	&.active {
		color: var(--caracas);
	}

	&:hover {
		color: var(--colon);
	}

	.counter {
		position: absolute;
		right: 10px;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background-color: var(--cebal);
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--cannes);
		line-height: 14px;

		> span {
			display: flex;
			margin-left: -1px;
		}
	}
}

.link-icon {
	color: var(--caracas);
	font-size: 24px;
}
</style>
