import { InitDataResponseData } from "@netgame/composables/src/useAppInitData";

import { apiClient } from "../../openapi";
import { CashPaymentStatus, DepositComplete, EventData, OrderSummary } from "../types";

type InitPresetPackage = NonNullable<NonNullable<InitDataResponseData>["popupPresetPackage"]>;
type InitPresetPackages = NonNullable<NonNullable<InitDataResponseData>["popupPresetPackages"]>;

type DepositCompleteExtended = DepositComplete & {
	popupPresetPackage: InitPresetPackage;
	popupPresetPackages: InitPresetPackages;
};

const useCash = () => {
	const { data: appInitData, refresh: refreshAppInitData } = useAppInitData();
	const { depositInfoData } = useDepositStreakData({ immediate: appInitData.value?.depositStreak?.isActive });

	const { data: profilePageData } = useAsyncFetch({
		path: "/rest/page/profile/",
		method: "get",
		options: {
			cached: true
		}
	});
	const { add } = useSockets();

	add("cash", ({ action, data }: EventData<CashPaymentStatus>) => {
		if (action === "paymentStatus") {
			if (!window?.$cash?.$router?.currentRoute?.path?.includes("/cash/failed/")) {
				window?.$cash?.$store?.dispatch?.("cash/setAlertInfo", data);
			}

			if (data?.status === "refresh") {
				window?.$cash?.$store?.dispatch?.("cash/setWidgetData", data?.widgetDto);
				window?.$cash?.$store?.dispatch?.("cash/setStep", "REFRESH_TOKEN");
				window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
			}

			if (data?.status === "decline") {
				if (window?.$cash?.$router?.currentRoute?.path?.includes("/cash/failed/")) {
					return;
				}

				if (data?.tryNextMethod) {
					const { presetId, offerType, methodId } = data.tryNextMethod;
					window?.$cash?.$router?.push?.(`/cash/failed/${presetId}/${offerType}/${methodId}`);
				} else {
					window?.$cash?.$router?.push?.("/cash/failed/");
				}
				window?.$store?.gaCash?.openPopup?.({
					location: "deposit",
					formName: "error_purchase",
					message: data.message || "We are failed to process your transaction. Please try again."
				});
			}
		}

		if (action === "3DSVerification") {
			dispatchQuasarEvent("OPEN_3DSVerification", { detail: data });
		}
	});

	add("deposit", (data: DepositCompleteExtended) => {
		const orderSummary = window?.$cash?.$store?.getters?.["cash/orderSummary"] as OrderSummary;
		const paymentMethod = window?.$cash?.$store?.getters["cash/paymentMethod"] as string;
		const paymentId = window?.$cash?.$store?.getters["cash/paymentId"] as number;

		if (data.action === "complete") {
			if (data.paymentMethod === "aonepay" && data.payment) {
				if (data.payment.id !== paymentId) {
					return;
				}

				window?.$cash?.$router?.push?.("/cash/success/");
			} else {
				window?.$cash?.$router?.push?.("/cash/success/");
			}

			const paymentType = () => {
				if (paymentMethod === "aonepay") {
					return "aonepay";
				}
				if (paymentMethod === "trustly") {
					return "bank_transfer";
				}
				if (paymentMethod === "card" || paymentMethod === "card-a") {
					return "payment_card";
				}
				return "apple_pay";
			};

			const bonus = () => {
				if (orderSummary?.promoOfferPreset?.entries) {
					return `${orderSummary.promoOfferPreset.entries} free entries`;
				}
				if (orderSummary?.preset?.entries) {
					return `${orderSummary.preset.entries} free entries`;
				}
				return "";
			};

			const offer = () => {
				if (orderSummary?.promoOfferPreset?.bestDeal) {
					return "best deal";
				}
				if (orderSummary?.promoOfferPreset?.mostPopular) {
					return "most popular";
				}
				if (orderSummary?.promoOfferPreset?.relatedPresetIds?.length) {
					return "upsale";
				}

				return "";
			};

			const coins = () => {
				if (orderSummary?.promoOfferPreset?.coins) {
					return orderSummary?.promoOfferPreset?.coins;
				}
				if (orderSummary?.preset?.coins) {
					return orderSummary?.preset?.coins;
				}
				return "";
			};

			dispatchQuasarEvent("DEPOSIT_COMPLETE");
			window?.$store?.gaCash?.purchase?.({
				type: paymentType(),
				coins: coins(),
				bonus: bonus(),
				offer: offer(),
				value: orderSummary?.money || "",
				quantity: data.playerData?.depositsCount || "",
				playerId: appInitData.value?.login || ""
			});

			dispatchGAEvent({
				event: "deposit",
				location: "header",
				step: "payment_complete",
				type: paymentType(),
				package: orderSummary?.money || "",
				quantity: data.playerData?.depositsCount || "",
				playerId: appInitData.value?.login || "",
				offer: offer()
			});

			dispatchFBEvents("trackCustom", "purchaseDeposit", {
				event_name: `PurchaseD${data.playerData.depositsCount}`,
				event_time: Date.now(),
				action_source: "website",
				value: orderSummary?.money,
				quantity: data.playerData.depositsCount
			});

			window?.$cash?.$store?.dispatch?.("cash/setDepositComplete", true);
			window?.$cash?.$store?.dispatch?.("cash/setAlertInfo", {
				bonusEntries: data?.bonusEntries,
				coins: data?.coins,
				entries: data?.entries,
				amount: orderSummary?.money
			});

			if (appInitData.value) {
				appInitData.value.popupPresetPackages = data.popupPresetPackages;
				appInitData.value.hasDeposits = true;
				appInitData.value.depositsCount = data.playerData.depositsCount;
				if (!appInitData.value?.popupPresetPackage) {
					return;
				}
				appInitData.value.popupPresetPackage = data.popupPresetPackage;
			}
		}

		if (data.action === "failed-complete") {
			const openAlertInfo = () => {
				const alertInfo = window?.$cash?.$store?.getters?.["cash/alertInfo"] as Record<string, string>;
				window?.$cash?.$router?.push?.("/cash/failed/");
				window?.$store?.gaCash?.openPopup?.({
					location: "deposit",
					formName: "error_purchase",
					message: alertInfo.message || "We are failed to process your transaction. Please try again."
				});
			};

			if (data.paymentMethod === "aonepay" && data.payment) {
				if (data.payment.id !== paymentId) {
					return;
				}

				openAlertInfo();
			} else {
				if (window?.$cash?.$router?.currentRoute?.path?.includes("/cash/failed/")) {
					return;
				}
				openAlertInfo();
			}
		}
	});

	add("playerIdentityVerification", ({ action }: EventData<unknown>) => {
		if (["rejected", "approved", "duplicateCredentials"].includes(action)) {
			window?.$cash?.$store?.dispatch?.("cash/setVerificationStatus", action);
			if (profilePageData.value) {
				profilePageData.value.payload.documentConfirmStatus = action;
			}
		}

		if (action === "approved") {
			window?.$store?.gaCash?.verification?.({
				step: "success",
				location: "verification_info"
			});
		}
		if (action === "rejected") {
			window?.$store?.gaCash?.verification?.({
				step: "canceled",
				location: "verification_info"
			});
		}
	});

	add("payment", ({ action, data }: EventData<unknown>) => {
		if (action === "3dsResult") {
			dispatchQuasarEvent("3DSResult", { detail: data });
		}
	});

	add("profile", ({ action, data }: EventData<NonNullable<InitDataResponseData>>) => {
		if (action === "profileUpdated" && appInitData.value) {
			appInitData.value = { ...appInitData.value, ...data };
		}
	});

	const {
		public: {
			cashScripts: { app, runtime, vendor }
		}
	} = useRuntimeConfig();
	const { data: rewardsData } = useRewardsData({ server: true });
	const { data: questInfo, refresh: refreshQuestInfo } = useGetPageQuestData({ immediate: false });
	const { data: promotionsData, refresh: refreshPromotionsData } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			cached: true,
			server: false
		}
	});
	const { data: balance } = useAsyncFetch({
		path: "/rest/player/balance/",
		method: "get",
		options: {
			cached: true
		}
	});
	const { initAndOpen } = useSupportChat();
	const { data: amountPromotionsData, refresh: refreshAmountPromotionsData } = useAsyncFetch({
		path: "/rest/page/promotions/amount/",
		method: "get",
		options: {
			cached: true,
			server: false
		}
	});
	const isLoginFlow = useCookie("isLoginFlow");
	const isGuestCookie = useCookie("isGuest");
	const showWelcomePopup = useCookie("showWelcomePopup");
	const presetPackageModalCookie = useCookie("presetPackageModal");

	const defaultLocale = "en";
	const possibleLocales = new Set(["en", "es"]);
	const route = useRoute();
	const pathLocale = route.path.split("/")[1] || defaultLocale;
	const locale = possibleLocales.has(pathLocale) ? pathLocale : defaultLocale;

	const runtimeLoaded = ref(false);
	const vendorLoaded = ref(false);
	const appLoaded = ref(false);

	const gettersDictionary = {
		"player/loggedIn": () => appInitData.value?.isGuest === false,
		"player/profile": () => appInitData.value,
		"balance/playerBalance": () => balance.value?.data,
		"depositStreak/data": () => depositInfoData.value,
		"lang/lang": () => locale,
		"player/gameMode": () => appInitData.value?.gameMode,
		"player/verificationStatus": () => {
			if (profilePageData.value?.payload?.documentConfirmStatus === "verified") {
				return "approved";
			}
			return profilePageData.value?.payload?.documentConfirmStatus;
		},
		"rewards/rewards": () => rewardsData.value?.data,
		"promotions/promotions": () => promotionsData.value?.payload,
		"modals/rewardsModal": () => ({
			isOpen: false
		}),
		"promotions/amountPromotions": () => amountPromotionsData.value?.amount,
		"quest/questData": () => questInfo.value?.quest,
		"game/currentGame": () => ({
			game: {
				slug: route.query.game
			}
		}),
		"modals/gameModal": () => ({
			isOpen: !!route.query.game
		})
	} as const;

	const actionsDictionary = {
		"player/incrementDepositCount": (_?: unknown) => {
			if (appInitData.value?.depositsCount !== undefined) {
				appInitData.value.depositsCount += 1;
			}
		},
		"player/logout": async () => {
			const isLoggedIn = gettersDictionary["player/loggedIn"]();
			if (isLoggedIn) {
				const response = await apiClient({
					path: "/rest/logout/",
					method: "get"
				});

				if (response) {
					refreshAppInitData();
					isGuestCookie.value = "true";
					presetPackageModalCookie.value = null;
					if (isLoginFlow.value) {
						isLoginFlow.value = null;
					}
					if (showWelcomePopup.value) {
						showWelcomePopup.value = null;
					}
					if (appInitData.value) {
						appInitData.value.isGuest = true;
					}
					navigateTo("/", { external: true });
				}
			}
		},
		"player/setProfilePhone": (payload: unknown) => {
			if (appInitData.value) {
				appInitData.value.phone = payload as string;
			}
		},
		"player/init": (_?: unknown) => {
			refreshAppInitData();
			refreshAmountPromotionsData();
			refreshPromotionsData();
			refreshQuestInfo();
		},
		"game/getGame": async () => {
			if (!route.query.game) {
				return;
			}
			await refreshNuxtData(`/rest/game/${route.query.game}/play/`);
		}
	} as const;

	useHead(
		{
			script: [
				{
					defer: true,
					src: runtime,
					onload: () => {
						runtimeLoaded.value = true;
					}
				},
				{
					defer: true,
					src: vendor,
					onload: () => {
						vendorLoaded.value = true;
					}
				},
				{
					defer: true,
					src: app,
					onload: () => {
						appLoaded.value = true;
					}
				}
			]
		},
		{ mode: "client" }
	);

	onMounted(() => {
		window.config = window.config || {};
		window.config.appMain = new Proxy(
			{},
			{
				get: (_, name) => appInitData.value?.[name as keyof InitDataResponseData]
			}
		);

		window.$store = window.$store || {};
		window.$store.$support = window.$store.$support || {
			open: () => {
				initAndOpen();
			}
		};
		window.$store.$cash = window.$store.$cash || {
			close: () => window?.$cash?.$router?.push?.("/"),
			removeProfile: () => window?.$cash?.$store?.dispatch?.("cash/setProfile", null),
			updateProfile: () => window?.$cash?.$store?.dispatch?.("cash/setProfile", appInitData.value)
		};

		window.$store.getters = new Proxy(
			{},
			{
				get: (_, name: keyof typeof gettersDictionary) => {
					if (!(name in gettersDictionary)) {
						return undefined;
					}
					return gettersDictionary[name]();
				}
			}
		);

		window.$store.dispatch = (action: string, payload?: unknown) => {
			// Yeah this is ugly but it's the only way to make it work
			// if you have a better idea, please let me know.
			const typedAction = action as keyof typeof actionsDictionary;
			if (!(action in actionsDictionary)) {
				return;
			}
			actionsDictionary[typedAction](payload);
		};

		(document.body as HTMLElement & { getEventListeners: () => void }).getEventListeners = () => ({});
	});

	watch([runtimeLoaded, vendorLoaded, appLoaded], ([runtime, vendor, app]) => {
		const everythingHasLoaded = runtime && vendor && app;
		if (everythingHasLoaded) {
			window?.$cash?.$store?.dispatch?.("cash/loadI18nMessages", locale);
		}
	});
};

export default useCash;
