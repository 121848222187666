<script lang="ts" setup>
const { t } = useT();
const isGuest = useIsGuest();
const prepareImgUrl = useImage();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/magic-boxes/bg-sidebar.jpg`, {
	format: "webp",
	with: "300",
	height: "111",
	quality: "100"
})}) no-repeat left center/cover`;
const { firstItemData } = useScretchCardData({ immediate: !isGuest.value });
</script>

<template>
	<NuxtLink to="/magic-boxes" class="magic-boxes-sidebar">
		<div class="info">
			<AText class="text-cannes" variant="topeka" :modifiers="['bold']" as="div">
				{{ t("Jungle Secrets") }}
			</AText>
			<AText class="text text-cannes" variant="texas" :modifiers="['condensed']" as="div">
				{{ t("Collect more FC to unlock boxes") }}
			</AText>
			<MMagicBoxesProgress :value="firstItemData.progress?.pointsComplete" :maxValue="firstItemData.progress?.points" />
		</div>
	</NuxtLink>
</template>

<style scoped lang="scss">
.magic-boxes-sidebar {
	display: block;
	border-radius: 10px;
	background: v-bind(imagePattern);
	padding: 8px 8px 0 60px;
	.text {
		margin: 2px 0 4px;
	}
	:deep(.magic-boxes-progress) {
		.icon-entries {
			font-size: 24px;
		}
		.text-value {
			font-size: 8px;
		}
	}
}
</style>
