<script setup lang="ts">
const { t } = useT();
const { referralInfo, handleClickCopyLink } = useReferralsInfo();
</script>
<template>
	<MRewardsWrapper image="nuxt-img/referral/invite.png" :button-text="t('Copy Link')" @click="handleClickCopyLink">
		<template #content>
			<AText class="text-cannes" variant="toledo" :modifiers="['bold']" as="div">
				{{ t("Invite friends!") }}
			</AText>
			<AText class="text-cocoa" variant="topeka" :modifiers="['normal']" as="div">
				<i18n-t keypath="You earn {key1}, {key2}">
					<template #key1>
						<AText class="text-caracas" variant="topeka" :modifiers="['semibold']">
							{{ numberFormat(Number(referralInfo?.prizeCoins)) }} GC
						</AText>
					</template>
					<template #key2>
						<AText class="text-chanda" variant="topeka" :modifiers="['semibold']">
							{{ numberFormat(Number(referralInfo?.prizeEntries)) }} FC
						</AText>
					</template>
				</i18n-t>
			</AText>
		</template>
	</MRewardsWrapper>
</template>
