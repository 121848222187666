import { LocationQueryValue } from "vue-router";

const locationQueryValueToString = (value: LocationQueryValue | LocationQueryValue[]) => {
	if (Array.isArray(value)) {
		return value[0] || "";
	}

	return value || "";
};

export default locationQueryValueToString;
