import revive_payload_client_uW8ea0PCdi from "/app/node_modules/.pnpm/nuxt@3.6.0_@types+node@18.16.19_eslint@8.28.0_typescript@5.1.6_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_AtFL1kSvFU from "/app/node_modules/.pnpm/nuxt@3.6.0_@types+node@18.16.19_eslint@8.28.0_typescript@5.1.6_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/app/apps/fortunewheelz/.nuxt/components.plugin.mjs";
import unhead_oCGEHUCHcN from "/app/node_modules/.pnpm/nuxt@3.6.0_@types+node@18.16.19_eslint@8.28.0_typescript@5.1.6_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_1eF1AfOfMg from "/app/node_modules/.pnpm/nuxt@3.6.0_@types+node@18.16.19_eslint@8.28.0_typescript@5.1.6_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_eGSOzQTqSg from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.12_vue@3.3.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_xExHLylzSG from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.12_vue@3.3.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_oCxCdUWtJZ from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_wiKCIV3dQ8 from "/app/node_modules/.pnpm/@nuxt+image-edge@1.0.0-27968280.9739e4d/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import chunk_reload_client_YSSITz5B4a from "/app/node_modules/.pnpm/nuxt@3.6.0_@types+node@18.16.19_eslint@8.28.0_typescript@5.1.6_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import mask_Tk3SUcMqzt from "/app/apps/fortunewheelz/plugins/mask.ts";
import vue3_toastify_OGYNDsiW9E from "/app/apps/fortunewheelz/plugins/vue3-toastify.ts";
import _01_raygun_client_kT05XfSKiz from "/app/node_modules/.pnpm/@netgame+layer-raygun@1.6.7/node_modules/@netgame/layer-raygun/plugins/01.raygun.client.ts";
export default [
  revive_payload_client_uW8ea0PCdi,
  router_AtFL1kSvFU,
  components_plugin_KR1HBZs4kY,
  unhead_oCGEHUCHcN,
  prefetch_client_1eF1AfOfMg,
  composition_eGSOzQTqSg,
  i18n_xExHLylzSG,
  plugin_oCxCdUWtJZ,
  plugin_wiKCIV3dQ8,
  chunk_reload_client_YSSITz5B4a,
  mask_Tk3SUcMqzt,
  vue3_toastify_OGYNDsiW9E,
  _01_raygun_client_kT05XfSKiz
]