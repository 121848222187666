/* eslint-disable indent */
import sha256 from "./sha256";

let savedLocation = "";
const createDepositHandler =
	({ getEmail, getPhone }: { getEmail: () => string; getPhone: () => string }) =>
	async ({
		event = "deposit",
		location,
		step,
		type,
		activePackage,
		playerId,
		quantity,
		offer
	}: {
		event?: string;
		location?: string;
		step?: string;
		type?: string;
		activePackage?: string;
		playerId?: string;
		quantity?: number;
		offer?: string;
	}) => {
		const email = getEmail();
		const phone = getPhone();
		savedLocation = location ?? savedLocation;
		window.dataLayer.push({
			event,
			location: location || savedLocation,
			step,
			type,
			package: activePackage,
			playerId,
			quantity,
			offer,
			...(quantity === 1
				? {
						currency: "USD",
						sha256_email_address: await sha256(email || ""),
						sha256_phone_number: await sha256(phone || "")
				  }
				: {})
		});
	};

export default createDepositHandler;
