<script setup lang="ts">
import { PromoData, WheelOfTreasure } from "types";

const { t } = useT();
const { isMobile } = useDevice();
const { gameOfWeek } = useHomePage();
const { homePromotions } = usePromotionsData();
const { data: appInitData } = useAppInitData();
const { data: bonusesData } = useGetBonusesData();
const { isActiveMiniWheel, depositInfoData } = useDepositStreakData();

const [isOpenPopper, toggleOpenPopper] = useToggle(false);

const wheelDurationLeftData = computed(() => bonusesData.value?.dailyWheel?.reasonValue ?? "");
const { durationLeft: wheelDurationLeft, reset } = useCountdown({
	timestamp: wheelDurationLeftData.value,
	formatToken: "HH:mm:ss"
});
const INVITE_FRIENDS = true;

const activeOffers = computed(() => {
	const filteredOffers = homePromotions.value.promotions?.reduce<PromoData[]>((acc, { data }) => {
		if (
			(data?.promoOfferPreset?.subType || data?.promoOfferPreset?.mostPopular || data?.promoOfferPreset?.bestDeal) &&
			data
		) {
			acc.push(data);
		}
		return acc;
	}, []);

	return filteredOffers?.sort((paymentA, paymentB) => Number(paymentB?.money) - Number(paymentA?.money));
});

const activePromoNumber = computed(() =>
	[
		!!gameOfWeek.value?.id,
		appInitData.value?.depositStreak?.isActive,
		appInitData.value?.scratchCardLottery?.isActive,
		bonusesData.value?.dailyWheel?.visible && !wheelDurationLeftData.value,
		appInitData.value?.season?.isEnabled,
		appInitData.value?.magicBox?.isActive,
		INVITE_FRIENDS
	].reduce((total, condition) => total + (condition ? 1 : 0), 0)
);

const counterNumber = computed(() => activePromoNumber.value + (activeOffers?.value?.length || 0) || 0);

const handleOpenPopper = () => {
	const notify = document.querySelector(".out-of-menu") as HTMLDivElement;
	notify.style.zIndex = "-1";
	toggleOpenPopper();
};

const handleClosePopper = () => {
	const notify = document.querySelector(".out-of-menu") as HTMLDivElement;
	notify.style.zIndex = "3";
	toggleOpenPopper();
};

watch(
	() => isOpenPopper.value,
	() => {
		if (isOpenPopper.value) {
			reset(wheelDurationLeftData.value);
			dispatchGAEvent({
				event: "open_popup",
				form_name: "reward_cabinet",
				location: "header"
			});
			return;
		}
		dispatchGAEvent({
			event: "close_popup",
			form_name: "reward_cabinet",
			location: "header"
		});
	}
);
</script>

<template>
	<div :class="['rewards-wrapper', { 'popper-open': isOpenPopper }]">
		<APopper placement="bottom-start" @open:popper="handleOpenPopper" @close:popper="handleClosePopper">
			<div class="btn-rewards" data-tid="header-rewards-btn">
				<NuxtImg src="nuxt-img/chest.png" format="webp" width="61" height="61" alt="chest" loading="lazy" />
				<AText v-if="!isMobile" variant="toledo" :modifiers="['medium']">{{ t("Rewards") }}</AText>
				<AText class="btn-counter" variant="ternopil" :modifiers="['semibold', 'condensed']" as="div">
					{{ counterNumber }}
				</AText>
			</div>
			<template #content="{ close }">
				<AOverlay
					v-if="isOpenPopper && isMobile"
					:modifiers="['fixed', 'flex-center']"
					bgColor="var(--changshu)"
					@click="close"
				/>
				<div class="rewards">
					<div class="top">
						<div>
							<AText class="text-cairo" variant="tanta" :modifiers="['bold']">
								{{ t("Available Rewards") }}
							</AText>
							<AText class="counter text-caracas" :modifiers="['semibold', 'condensed']">
								{{ counterNumber }}
							</AText>
						</div>
						<ASvg name="close" class="close" filled @click="close" />
					</div>
					<div class="items">
						<MRewardsGameOfWeek v-if="gameOfWeek?.id" :data="gameOfWeek" />
						<MRewardsWheelOfFortune
							v-if="bonusesData?.dailyWheel?.visible"
							:data="bonusesData"
							:wheelDurationLeft="wheelDurationLeft"
						/>
						<MRewardsScratchCards v-if="appInitData?.scratchCardLottery?.isActive" />
						<MRewardsWheelOfTreasure
							v-if="appInitData?.depositStreak?.isActive && depositInfoData?.success"
							:data="depositInfoData as WheelOfTreasure"
							:active="isActiveMiniWheel"
						/>
						<template v-for="offer in activeOffers" :key="offer?.preset?.id">
							<MRewardsPromo v-if="offer" :data="offer" />
						</template>
						<MRewardsLeprechaunSeason
							v-if="appInitData?.season?.isEnabled"
							:currentLevel="appInitData?.season?.currentLevel"
							:totalProgressPercents="appInitData?.season?.totalProgressPercents"
						/>
						<MRewardsMagicBoxes v-if="appInitData?.magicBox?.isActive" />
						<MRewardsInvite />
					</div>
				</div>
			</template>
		</APopper>
	</div>
</template>

<style scoped lang="scss">
.rewards-wrapper {
	position: relative;
}
.btn-rewards {
	position: relative;
	width: max-content;
	background: var(--gilbert);
	padding: gutter(0.375) gutter(0.625);
	border-radius: 8px;
	@include media-breakpoint-up(lg) {
		padding: gutter(1);
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: gutter(1);
		cursor: pointer;
		transition: all 200ms ease-in-out;
		&:hover {
			box-shadow: 0 0 10px 0 var(--caracas);
		}
	}
	img {
		width: 30px;
		height: 30px;
	}
	.btn-counter {
		border-radius: 50%;
		background: var(--gdansk);
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		@include media-breakpoint-down(lg) {
			position: absolute;
			top: -8px;
			right: -10px;
		}
	}
}
:deep(.popper) {
	--popper-theme-background-color: var(--cambe);
	--popper-theme-background-color-hover: var(--cambe);
	--popper-theme-box-shadow: 0 0 20px 0 var(--caracas);
	--popper-theme-padding: 16px;
	@include media-breakpoint-down(lg) {
		--popper-theme-border-radius: 16px 16px 0 0;
		position: fixed !important;
		display: block !important;
		top: auto !important;
		bottom: calc(-100vh - 20px) !important;
		left: 0 !important;
		right: 0 !important;
		transform: none !important;
		width: 100vw;
		max-width: 100vw;
		height: auto;
		max-height: 100dvh;
		overflow-y: auto;
		transition: all 400ms ease-in-out !important;
		.popper-open & {
			bottom: 0 !important;
		}
		.overlay {
			z-index: -1;
		}
	}
	@include media-breakpoint-up(lg) {
		--popper-theme-border-radius: 16px;
	}
}
.rewards {
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: 360px;
	}
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: gutter(1);
		.counter {
			background: var(--craiova);
			padding: gutter(0.7) gutter(1);
			border-radius: 5px;
			margin-left: gutter(1);
		}
		.close {
			font-size: 30px;
			transition: all 200ms ease-in-out;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
</style>
