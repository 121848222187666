import useStatefulCookie from "./useStatefulCookie";

const useCookiesSetPromoCode = () => {
	const route = useRoute();
	const { invited_by: invitedBy } = route.query;
	const fromQuery = ref(locationQueryValueToString(invitedBy));

	const invitedByCookie = useStatefulCookie<string | null>("invited_by", {
		expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
		path: "/"
	});

	watch(fromQuery, (val) => {
		invitedByCookie.value = val;
	});

	onMounted(() => {
		if (!invitedBy) {
			return null;
		}
		invitedByCookie.value = locationQueryValueToString(invitedBy);
	});

	return {
		invitedByCookie: invitedByCookie.value ? invitedByCookie : fromQuery
	};
};

export default useCookiesSetPromoCode;
