<script setup lang="ts">
import { ref, watchEffect } from "#imports";

import { kebabCase } from "scule";

const SVG_PREFIX = "nxt-svg-";

const compileSvgId = (name: string) => `${SVG_PREFIX}${kebabCase(name)}`;
const addIdToSvg = (svg: string, id: string) => svg.replace("<svg", `<svg id="${id}"`);
const getSVGAttributes = (svgString: string) => {
	const regex = /<svg([^>]*)>/;
	const match = svgString.match(regex);

	if (match && match[1]) {
		// Extracting attributes from the matched string
		const attributesString = match[1].trim();
		return attributesString;
	}
	return "";
};
const registry = useSvgRegistry();
const props = withDefaults(
	defineProps<{
		name: string;
		filled?: boolean;
	}>(),
	{ filled: false }
);

const svg = ref<string>("");

const getIcon = async () => {
	try {
		const iconsImport = import.meta.glob("assets/**/**.svg", {
			as: "raw",
			eager: false
		});
		const rawIcon = await iconsImport[`/assets/${props.name}.svg`]();
		const id = compileSvgId(props.name);
		const svgStr = addIdToSvg(rawIcon, id);
		const svgAttributes = getSVGAttributes(rawIcon);
		svg.value = `<svg ${svgAttributes}><use xlink:href="#${id}"></use></svg>`;
		if (registry.value.has(id)) {
			return;
		}
		registry.value.set(id, svgStr);
	} catch {
		console.error(`[a-svg] Svg '${props.name}' doesn't exist in 'assets'`);
	}
};

await getIcon();

watchEffect(getIcon);
</script>

<template>
	<span :class="{ fill: filled }" v-html="svg" />
</template>
<style scoped lang="scss">
.fill,
.fill * {
	fill: currentColor !important;
}

span {
	display: inline-flex;
	flex-shrink: 0;

	&:deep(svg) {
		width: 1em;
		height: 1em;
	}
}
</style>
