<script setup lang="ts">
import { Game } from "~/types";

const props = defineProps<{
	data?: Game;
}>();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { t } = useT();
const route = useRoute();
const error = useError();
const isGuest = useIsGuest();

const { open, close } = useAppModals();
const { handleOpenGame } = useOpenGame(open);

const handleClick = async () => {
	dispatchGAEvent({
		event: "click_button",
		location: "reward_cabinet",
		button_name: "play"
	});

	if (route.query.game) {
		navigateTo(route.path, { replace: true });
		close("LazyOModalGame");
	}

	if (error.value && "statusCode" in error.value && error.value?.statusCode === 404) {
		clearError({ redirect: "/issues/all-games/" });
		await navigateTo({
			path: "/issues/all-games/",
			query: { game: props.data?.slug, isMoney: !isGuest.value ? "true" : undefined }
		});
	}

	handleOpenGame(props.data?.slug);
};
</script>

<template>
	<MRewardsWrapper class="row" :image="`${baseImageUrl}${data?.img}`" :button-text="t('Play')" @click="handleClick">
		<template #content>
			<ABadge background="var(--gilbert)" variant="info" autosize>
				<AText variant="ternopil" :modifiers="['bold', 'uppercase']">{{ t("Game of the week") }}</AText>
			</ABadge>
			<AText class="text-cannes" variant="toledo" :modifiers="['bold']" as="div">
				{{ data?.title }}
			</AText>
		</template>
	</MRewardsWrapper>
</template>

<style lang="scss" scoped>
.row:deep(img) {
	border-radius: 10px;
	box-shadow: 0 0 10px 0 var(--caracas);
}
</style>
