import useAsyncFetch from "./useAsyncFetch";
import useGamesState from "./useGamesState";

const useGetGameFiltersData = () => {
	const { add, select } = useGamesState();
	const asyncData = useAsyncFetch({
		path: "/rest/get-game-filters/",
		method: "get",
		options: {
			cached: true,
			default: () => ({
				games: Array.from({ length: 16 }).map((_, id) => ({ skeleton: true, id }))
			}),
			transform(data) {
				return {
					...data?.data,
					games: add(data.data?.games || [])
				};
			}
		}
	});
	const games = computed(() => select(asyncData.data.value.games));

	return { ...asyncData, games };
};

export default useGetGameFiltersData;
