<script setup lang="ts">
const props = defineProps<{
	currentLevel?: number;
	totalProgressPercents?: number;
}>();

const { t } = useT();

const { open } = useAppModals();

const valueProgress = computed(() => props?.totalProgressPercents || 0);
const currentLevel = computed(() => props?.currentLevel || 1);

const prizeIcon = computed(() => {
	if (currentLevel.value !== 1 && currentLevel.value === 30) {
		return "/nuxt-img/seasons/leprechaun/tower-prize-gold.png";
	}
	return "/nuxt-img/seasons/leprechaun/tower-prize-base.png";
});

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "reward_cabinet",
		button_name: "leprechaun_season"
	});
	open("LazyOModalSeasonTower");
};
</script>
<template>
	<MRewardsWrapper :image="prizeIcon" :button-text="t('Play')" @click="handleClick">
		<AText class="lvl" variant="tlalpan" :modifiers="['extrabold']" as="div">{{ currentLevel }}</AText>
		<template #content>
			<AText class="season-title" variant="toledo" :modifiers="['bold']" as="div">{{ t("Leprechaun's Gold") }}</AText>
			<AProgressLinear
				class="season-progress"
				:value="valueProgress"
				gradientId="var(--guli)"
				backgroundColor="var(--cheng)"
				borderColor="var(--chiclayo)"
				borderRadius="29px"
				height="13px"
			/>
		</template>
	</MRewardsWrapper>
</template>
<style lang="scss" scoped>
.lvl {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 7px;
	top: calc(50% - 21px);
	width: 42px;
	height: 42px;
	text-align: center;
	text-shadow: 2px 1px var(--changshu);
}

.season-title {
	margin-bottom: gutter(0.5);
}

.season-progress {
	max-width: 147px;
}
</style>
