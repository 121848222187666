export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"Personalized approach. Fortune coins, daily. Support 24/7. Play Social Promotional Games online at fortunewheelz.com"},{"hid":"og:url","name":"og:url","content":"https://fortunewheelz.com/"},{"name":"og:type","content":"website"},{"hid":"og:title","name":"og:title","content":"Fortune Wheelz Platform for Social Gaming"},{"hid":"og:description","name":"og:description","content":"Personalized approach. Fortune coins, daily. Support 24/7. Play Social Promotional Games online at fortunewheelz.com"},{"name":"og:site_name","content":"Fortune Wheelz"},{"hid":"og:image","name":"og:image","content":"https://fortunewheelz.com/uploads/media/ogImage.jpg"},{"hid":"twitter:title","name":"twitter:title","content":"Fortune Wheelz Platform for Social Gaming"},{"hid":"twitter:description","name":"twitter:description","content":"Personalized approach. Free coins, daily. Support 24/7. Play Social Promotional Games online at fortunewheelz.com"},{"name":"twitter:site","content":"Fortune Wheelz"},{"hid":"twitter:image","name":"twitter:image","content":"https://fortunewheelz.com/uploads/media/ogImage.jpg"},{"hid":"twitter:image:src","name":"twitter:image:src","content":"https://fortunewheelz.com/uploads/media/ogImage.jpg"},{"name":"twitter:card","content":"summary_large_image"}],"link":[{"rel":"icon","type":"image/png","href":"/nuxt-img/favicon-32x32.png"},{"rel":"icon","type":"image/png","href":"/nuxt-img/favicon-16x16.png"},{"rel":"shortcut icon","href":"/nuxt-img/favicon.ico"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"class":"fortuneWheelz"},"viewport":"width=device-width, initial-scale=1, maximum-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null