<script setup lang="ts">
import { WheelOfTreasure } from "types";

const props = defineProps<{
	data?: WheelOfTreasure;
	active?: boolean;
}>();

const { t } = useT();
const { open } = useAppModals();
const { isPercentEnabled, depositInfoData, timerWheelDisabled } = useDepositStreakData({ immediate: false });

const buttonName = computed(() => {
	if (depositInfoData.value.secondsToResetStreakCounter) {
		return timerWheelDisabled.value;
	}
	if (props.active) {
		return t("Spin");
	}
	return t("Buy");
});

const handleClick = () => {
	if (props.active) {
		dispatchGAEvent({
			event: "click_button",
			location: "reward_cabinet",
			button_name: "spin"
		});
		open("LazyOModalWheelOfTreasurePlayWheel");
		return;
	}
	if (depositInfoData.value.secondsToResetStreakCounter) {
		navigateTo("/wheel-of-treasure", { external: true });
		return;
	}
	dispatchGAEvent({
		event: "click_button",
		location: "reward_cabinet",
		button_name: "buy"
	});
	window?.$store?.gaCash?.deposit?.({
		location: "reward_cabinet"
	});
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<MRewardsWrapper
		class="row"
		:image="`nuxt-img/wheel-of-treasure/wheel/${isPercentEnabled ? 'mini-wheel-percent' : 'mini-wheel'}.png`"
		:button-text="buttonName"
		:check-time="!!depositInfoData.secondsToResetStreakCounter"
		@click="handleClick"
	>
		<template #content>
			<LazyMWheelOfTreasureStepsProgressBar>
				<template #top>
					<AText class="text-cannes" variant="toledo" :modifiers="['bold']" as="div">
						{{ t("Wheel of Treasure") }}
					</AText>
				</template>
				<template #bottom>
					<AText class="text-cocoa" variant="ternopil" :modifiers="['normal']" as="div">
						<i18n-t keypath="Purchases {key} completed">
							<template #key>
								{{ `${data?.currentCountDeposit}/${data?.maxNumberOfDeposit}` }}
							</template>
						</i18n-t>
					</AText>
				</template>
			</LazyMWheelOfTreasureStepsProgressBar>
		</template>
	</MRewardsWrapper>
</template>
<style lang="scss" scoped>
.row :deep(.progress) {
	background: transparent;
	padding: 0;
	.progress-bar {
		margin-top: gutter(0.5);
		width: fit-content;
	}
	.step:not(.active) {
		background-color: var(--cheng);
	}
}
</style>
