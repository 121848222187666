/* eslint-disable indent */
import { NitroFetchOptions, NitroFetchRequest } from "nitropack";

import { replacePathParameters } from "./replacePathParameters";
import { v1 } from "./schemas";
import { ExtractFromAPI, PossibleMethods, Parameters } from "./types";

export const apiClient = <
	Path extends keyof v1.paths,
	Method extends keyof v1.paths[Path],
	Response extends ExtractFromAPI<v1.paths, Path, Method>
>({
	path,
	method,
	options,
	parameters
}: {
	path: Path;
	method: Method;
	options?: NitroFetchOptions<NitroFetchRequest>;
	parameters?: Parameters<v1.paths[Path][Method]>;
}) => {
	const { path: parametersPaths, body } = (parameters || {}) as unknown as {
		path: Record<string, string>;
		body: Record<string, string>;
	};
	// eslint-disable-next-line no-prototype-builtins
	const isQuasar = typeof window !== "undefined" && window.hasOwnProperty("__Q_META__");
	return $fetch<Response>(replacePathParameters(path, parametersPaths), {
		baseURL: isQuasar ? undefined : "/api",
		...options,
		body: body || options?.body,
		method: method as PossibleMethods | undefined
	});
};
