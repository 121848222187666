import { AsyncDataOptions } from "#app";

import { v1, Parameters as Params, ExtractFromAPI } from "@netgame/openapi";
import { NitroFetchOptions, NitroFetchRequest } from "nitropack";

import useAsyncFetch from "./useAsyncFetch";
import useSockets from "./useSockets";

type EventData<TData> = {
	type: string;
	action: string;
	data: TData;
};

type BalanceUpdate = {
	balance: string;
	bonus: Array<Record<string, unknown>>;
	bonusV2: string;
	cashUrl: string;
	cashbackAmount: number;
	compPoints: string;
	compPointsTotal: string;
	currency: string;
	entries: number;
	event: string;
	freespins_remain: number;
	leftRoleplay: number;
	roleplayLeftInPercent: number;
	roleplaying: string;
	tourPoints: number;
	winnings: string;
};

type BalanceResponse = ExtractFromAPI<v1.paths, "/rest/player/balance/", "get">;
type FetchOptions = NitroFetchOptions<NitroFetchRequest> & Params<v1.paths["/rest/player/balance/"]["get"]>;

const useBalance = (payload?: {
	options: AsyncDataOptions<BalanceResponse> & { cached?: true; key?: string };
	fetchOptions?: FetchOptions | (() => FetchOptions);
}) => {
	const { data: balance, ...rest } = useAsyncFetch({
		path: "/rest/player/balance/",
		method: "get",
		options: payload?.options,
		fetchOptions: payload?.fetchOptions
	});
	const { add } = useSockets();

	add("setBalance", (message: EventData<BalanceUpdate>) => {
		if (balance.value) {
			balance.value.data = message.data;
		}
		window?.$cash?.$store?.dispatch?.("cash/setPlayerBalance", message.data);
	});

	const tourPoints = computed(() => balance.value?.data?.tourPoints ?? 0);
	const entries = computed(() => (Array.isArray(balance.value?.data) ? 0 : balance.value?.data?.entries ?? 0));
	const winnings = computed(() => Number(balance.value?.data?.winnings ?? 0));

	return {
		balance,
		winnings,
		tourPoints,
		entries,
		...rest
	};
};

export default useBalance;
