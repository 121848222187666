<script lang="ts">
import { HTMLProps } from "../types-utils";
export const variantList = [
	"tachiv",
	"taiz",
	"talca",
	"tallinn",
	"tampa",
	"tangier",
	"tanta",
	"tustan",
	"tanzay",
	"tashkent",
	"tebessa",
	"tempe",
	"ternopil",
	"texas",
	"tivat",
	"tokio",
	"toledo",
	"topeka",
	"toronto",
	"torreon",
	"toulouse",
	"taipei",
	"tirana",
	"tucson",
	"tulsa",
	"tunis",
	"tangier",
	"tachiv",
	"tanzay",
	"torreon",
	"toulouse",
	"talca",
	"turin",
	"trieste",
	"tuzla",
	"tiraspol",
	"tonk",
	"tetouan",
	"tarsus",
	"taylor",
	"trabzon",
	"tbilisi",
	"tuxtla",
	"toulon",
	"tabuk",
	"tamale",
	"taizhou",
	"tlaquepaque",
	"tlalpan",
	"tuxtla",
	"teresina",
	"takoradi",
	"tasikmalaya",
	"toyonaka",
	"tunja",
	"tyre",
	"tainan",
	"tsu",
	"takeo",
	"touba",
	"trushky",
	"taranto"
] as const;

export const modifierList = [
	"bold",
	"normal",
	"semibold",
	"medium",
	"extrabold",
	"black",
	"linght",
	"hairline",
	"light",
	"link",
	"underline",
	"lineThrough",
	"nowrap",
	"uppercase",
	"lowercase",
	"capitalize",
	"inheritColor",
	"inheritSize",
	"wide",
	"ellipsis",
	"italic",
	"center",
	"left",
	"right",
	"condensed"
] as const;
</script>

<script setup lang="ts">
withDefaults(
	defineProps<
		{
			variant?: (typeof variantList)[number];
			as?: string;
			modifiers?: Array<(typeof modifierList)[number]>;
			stroke?: string;
			gradient?: string;
		} & HTMLProps
	>(),
	{ variant: "tempe", as: "span", stroke: "", gradient: "" }
);
</script>

<template>
	<component
		:is="as"
		:class="[stroke ? 'strokeful' : undefined, gradient ? 'gradientful' : undefined, variant, ...(modifiers || [])]"
		:style="{ '--a-text-gradient': gradient || undefined }"
	>
		<template v-if="stroke">
			<span class="backdrop" :style="{ '-webkit-text-stroke': stroke || undefined }">
				<slot />
			</span>
			<span>
				<slot />
			</span>
		</template>
		<slot v-else />
	</component>
</template>

<style lang="scss">
:root {
	--a-text-link-color: var(--changchun);
}

.fortuneWheelz {
	--a-text-link-color: var(--chaoyang);
}
</style>

<style scoped lang="scss">
/* Cities starting from T https://worldpopulationreview.com/world-cities/starting-with/t */
.tainan {
	font-size: 100px;
	@include media-breakpoint-down(md) {
		font-size: 54px;
	}
}

.tustan {
	font-size: 72px;
}

.tyre {
	font-size: 64px;
}

.takeo {
	font-size: 60px;
}
.teresina {
	font-size: 56px;
	@include media-breakpoint-down(md) {
		font-size: 32px;
	}
}
.tabuk {
	font-size: 54px;
}
.tuxtla {
	font-size: 52px;
}
.tetouan {
	font-size: 50px;
	@include media-breakpoint-down(md) {
		font-size: 38px;
	}
}
.touba {
	font-size: 50px;
}
.tunja {
	font-size: 48px;
}
.tashkent {
	font-size: 46px;
}

.tokio {
	font-size: 43px;
}
.toyonaka {
	font-size: 40px;
	@include media-breakpoint-down(md) {
		font-size: 32px;
	}
}
.tlaquepaque {
	font-size: 40px;
	@include media-breakpoint-down(md) {
		font-size: 24px;
	}
}
.taizhou {
	font-size: 40px;
	@include media-breakpoint-down(md) {
		font-size: 16px;
	}
}
.taiz {
	font-size: 40px;
	line-height: 1.2;
}

.tasikmalaya {
	font-size: 38px;
	@include media-breakpoint-down(lg) {
		font-size: 24px;
	}
}
.tamale {
	font-size: 38px;
}

.toronto {
	font-size: 37px;
}

.tucson {
	font-size: 36px;
	line-height: 1.5;
	letter-spacing: -0.01962em;
	@include media-breakpoint-down(sm) {
		font-size: 28px;
		line-height: calc(34px / 28px);
	}
}

.trushky {
	font-size: 36px;
}

.tanzay {
	font-size: 32px;
	line-height: 1.2;
}

.tarsus {
	font-size: 32px;
	@include media-breakpoint-down(sm) {
		font-size: 28px;
	}
}
.torreon {
	font-size: 32px;
	line-height: 40px;
	@include media-breakpoint-down(sm) {
		font-size: 24px;
		line-height: calc(30px / 24px);
	}
}

.tachiv {
	font-size: 30px;
	line-height: 1.2;
}

.tunis {
	font-size: 28px;
	@include media-breakpoint-down(md) {
		font-size: 24px;
	}
}

.tangier {
	font-size: 28px;
	@include media-breakpoint-down(md) {
		font-size: 20px;
	}
}

.tebessa {
	font-size: 28px;
	@include media-breakpoint-down(md) {
		font-size: 16px;
	}
}

.tirana {
	font-size: 28px;
}

.toulon {
	font-size: 26px;
}
.tampa {
	font-size: 24px;
	line-height: 28px;
	@include media-breakpoint-down(lg) {
		font-size: 20px;
		line-height: 24px;
	}
}
.tiraspol {
	font-size: 24px;
	@include media-breakpoint-down(md) {
		font-size: 20px;
	}
}

.taylor {
	font-size: 24px;
	@include media-breakpoint-down(md) {
		font-size: 16px;
	}
}
.takoradi {
	font-size: 24px;
	@include media-breakpoint-down(md) {
		font-size: 14px;
	}
}
.taipei {
	font-size: 24px;
}

.tulsa {
	font-size: 22px;
}

.tuzla {
	font-size: 20px;
	@include media-breakpoint-down(md) {
		font-size: 16px;
	}
}

.tanta {
	font-size: 20px;
}

.turin {
	font-size: 18px;
}
.trabzon {
	font-size: 18px;
	@include media-breakpoint-down(lg) {
		font-size: 14px;
	}
}
.tsu {
	font-size: 17px;
}

.tonk {
	font-size: 16px;
	@include media-breakpoint-down(md) {
		font-size: 14px;
	}
}
.toledo {
	font-size: 16px;
}

.tbilisi {
	font-size: 15px;
}
.tlalpan {
	font-size: 14px;
	@include media-breakpoint-down(md) {
		font-size: 12px;
	}
}

.tempe {
	font-size: 14px;
}

.talca {
	font-size: 13px;
}

.topeka {
	font-size: 12px;
}
.tivat {
	font-size: 11px;
}
.ternopil {
	font-size: 10px;
}

.tallinn {
	font-size: 9px;
}

.texas {
	font-size: 8px;
}

.trieste {
	font-size: 7px;
}

.taranto {
	font-size: 6px;
}

.condensed {
	line-height: 1;
}

.black {
	font-weight: 900;
}

.extrabold {
	font-weight: 800;
}

.bold {
	font-weight: 700;
}

.semibold {
	font-weight: 600;
}

.normal {
	font-weight: 400;
}

.medium {
	font-weight: 500;
}

.linght {
	font-weight: 300;
}

.hairline {
	font-weight: 100;
}

.nowrap {
	white-space: nowrap;
}

.uppercase {
	text-transform: uppercase;
}

.italic {
	font-style: italic;
}
.lowercase {
	text-transform: lowercase;
}

.link {
	color: var(--a-text-link-color);
	cursor: pointer;
}

.underline {
	text-decoration: underline;
}

.lineThrough {
	text-decoration: line-through;
}

.inheritColor {
	color: inherit;
}

.inheritSize {
	font-size: inherit;
}

.capitalize {
	text-transform: capitalize;
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}
.strokeful {
	position: relative;
	z-index: 1;

	.backdrop {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		z-index: -1;
		user-select: none;
		pointer-events: none;
	}
}

.ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.gradientful > :slotted(span:not(.backdrop)) {
	background-image: var(--a-text-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
</style>
