<script setup lang="ts">
import { BonusesResponse } from "types";
const props = defineProps<{
	data: BonusesResponse;
	wheelDurationLeft: string;
}>();

const { t } = useT();
const { open } = useAppModals();

const timerBtn = computed(() => props.data.dailyWheel?.visible && props.data.dailyWheel.reasonType === "time");

const handleClick = () => {
	if (timerBtn.value) {
		return;
	}

	dispatchGAEvent({
		event: "click_button",
		location: "reward_cabinet",
		button_name: "spin"
	});
	open("LazyOModalDailyWheel");
};
</script>
<template>
	<MRewardsWrapper
		class="row"
		image="nuxt-img/daily-wheel/mini-wheel.png"
		:button-text="timerBtn ? wheelDurationLeft : t('Spin')"
		:check-time="timerBtn"
		@click="handleClick"
	>
		<template #content>
			<AText class="text-cannes" variant="toledo" :modifiers="['bold']" as="div">
				{{ t("Wheel of Fortune") }}
			</AText>
			<AText class="text-cocoa" variant="topeka" :modifiers="['normal']" as="div">
				{{ t("Spin every 12 hours") }}
			</AText>
		</template>
	</MRewardsWrapper>
</template>
<style lang="scss" scoped>
.box.row {
	background: url("/nuxt-img/daily-wheel/bonuses-bg.jpg") no-repeat center center/cover !important;
}
</style>
