<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const loginGuardFunction = useLoginGuard();
const isGuest = useIsGuest();
const { depositInfoData, isPercentEnabled } = useDepositStreakData({ immediate: !isGuest.value });

const handleWheelOfTreasureClick = () => {
	loginGuardFunction({
		success: () => {
			dispatchGAEvent({
				event: "click_button",
				button_name: "wheel_of_treasure_progress_bar",
				location: "menu"
			});
			navigateTo("/wheel-of-treasure");
		},
		fail: () => {
			open("LazyOModalSignup", { location: "left_menu" });
		}
	});
};
</script>

<template>
	<NuxtLink
		v-if="depositInfoData.success"
		class="wheel-wrap"
		to="/wheel-of-treasure"
		@click="handleWheelOfTreasureClick()"
	>
		<LazyMWheelOfTreasureFakeWheel :wheelSize="44" clearSectors :isPercentEnabled="isPercentEnabled" />
		<LazyMWheelOfTreasureStepsProgressBar>
			<template #top>
				<AText class="progress-text text-cannes" variant="topeka" :modifiers="['bold']" as="div">
					{{ t("Wheel of Treasure") }}
				</AText>
			</template>
			<template #bottom>
				<AText class="progress-text text-cocoa" variant="ternopil" as="div">
					<i18n-t keypath="You've got {key} purchases">
						<template #key>
							{{ `${depositInfoData?.currentCountDeposit}/${depositInfoData?.maxNumberOfDeposit}` }}
						</template>
					</i18n-t>
				</AText>
			</template>
		</LazyMWheelOfTreasureStepsProgressBar>
	</NuxtLink>
</template>

<style lang="scss" scoped>
.wheel-wrap {
	position: relative;
	padding: 0;
	.fake-wheel {
		position: absolute;
		top: calc(50% - 28px);
		left: 0;
		width: 56px;
		height: 56px;
	}
	.progress {
		margin-left: 28px;
		padding: 6px 6px 6px 36px;
		border-radius: 0 10px 10px 0;
		&:deep(.progress-bar) {
			margin: 2px 0;
		}
	}
}
</style>
