import { default as account6cvE9js2nmMeta } from "/app/apps/fortunewheelz/pages/account.vue?macro=true";
import { default as how_45it_45worksM9Ybd3vFSWMeta } from "/app/apps/fortunewheelz/pages/how-it-works.vue?macro=true";
import { default as indexO8vsNFLjw9Meta } from "/app/apps/fortunewheelz/pages/index.vue?macro=true";
import { default as _91pageName_93Bn4wuFzTvOMeta } from "/app/apps/fortunewheelz/pages/issues/[pageName].vue?macro=true";
import { default as favoritessAX74q97rIMeta } from "/app/apps/fortunewheelz/pages/issues/favorites.vue?macro=true";
import { default as magic_45boxeshL4NYv6QW0Meta } from "/app/apps/fortunewheelz/pages/magic-boxes.vue?macro=true";
import { default as authlEkMYNffebMeta } from "/app/apps/fortunewheelz/pages/not-released/auth.vue?macro=true";
import { default as gameHb4FK5vR1bMeta } from "/app/apps/fortunewheelz/pages/not-released/game.vue?macro=true";
import { default as _91pageName_93VvncT2ddlPMeta } from "/app/apps/fortunewheelz/pages/not-released/games/[pageName].vue?macro=true";
import { default as invite_45friends0HNUDjgjJ3Meta } from "/app/apps/fortunewheelz/pages/not-released/invite-friends.vue?macro=true";
import { default as promotionsnGixWVbqHKMeta } from "/app/apps/fortunewheelz/pages/not-released/promotions.vue?macro=true";
import { default as racesvICQKOK4WGMeta } from "/app/apps/fortunewheelz/pages/not-released/races.vue?macro=true";
import { default as translations_45showcaseEHXl3GVovTMeta } from "/app/apps/fortunewheelz/pages/not-released/translations-showcase.vue?macro=true";
import { default as _91name_93gjJu3cakn0Meta } from "/app/apps/fortunewheelz/pages/page/[name].vue?macro=true";
import { default as promotionshF66CfpS2aMeta } from "/app/apps/fortunewheelz/pages/promotions.vue?macro=true";
import { default as referral_45infoas4dn6PE8VMeta } from "/app/apps/fortunewheelz/pages/referral-info.vue?macro=true";
import { default as scratch_45cardst2VdAkSUPVMeta } from "/app/apps/fortunewheelz/pages/scratch-cards.vue?macro=true";
import { default as wheel_45of_45treasurea4pK18TakvMeta } from "/app/apps/fortunewheelz/pages/wheel-of-treasure.vue?macro=true";
export default [
  {
    name: account6cvE9js2nmMeta?.name ?? "account___en",
    path: account6cvE9js2nmMeta?.path ?? "/account",
    meta: account6cvE9js2nmMeta || {},
    alias: account6cvE9js2nmMeta?.alias || [],
    redirect: account6cvE9js2nmMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/account.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45worksM9Ybd3vFSWMeta?.name ?? "how-it-works___en",
    path: how_45it_45worksM9Ybd3vFSWMeta?.path ?? "/how-it-works",
    meta: how_45it_45worksM9Ybd3vFSWMeta || {},
    alias: how_45it_45worksM9Ybd3vFSWMeta?.alias || [],
    redirect: how_45it_45worksM9Ybd3vFSWMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexO8vsNFLjw9Meta?.name ?? "index___en",
    path: indexO8vsNFLjw9Meta?.path ?? "/",
    meta: indexO8vsNFLjw9Meta || {},
    alias: indexO8vsNFLjw9Meta?.alias || [],
    redirect: indexO8vsNFLjw9Meta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93Bn4wuFzTvOMeta?.name ?? "issues-pageName___en",
    path: _91pageName_93Bn4wuFzTvOMeta?.path ?? "/issues/:pageName()",
    meta: _91pageName_93Bn4wuFzTvOMeta || {},
    alias: _91pageName_93Bn4wuFzTvOMeta?.alias || [],
    redirect: _91pageName_93Bn4wuFzTvOMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/issues/[pageName].vue").then(m => m.default || m)
  },
  {
    name: favoritessAX74q97rIMeta?.name ?? "issues-favorites___en",
    path: favoritessAX74q97rIMeta?.path ?? "/issues/favorites",
    meta: favoritessAX74q97rIMeta || {},
    alias: favoritessAX74q97rIMeta?.alias || [],
    redirect: favoritessAX74q97rIMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/issues/favorites.vue").then(m => m.default || m)
  },
  {
    name: magic_45boxeshL4NYv6QW0Meta?.name ?? "magic-boxes___en",
    path: magic_45boxeshL4NYv6QW0Meta?.path ?? "/magic-boxes",
    meta: magic_45boxeshL4NYv6QW0Meta || {},
    alias: magic_45boxeshL4NYv6QW0Meta?.alias || [],
    redirect: magic_45boxeshL4NYv6QW0Meta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/magic-boxes.vue").then(m => m.default || m)
  },
  {
    name: authlEkMYNffebMeta?.name ?? "not-released-auth___en",
    path: authlEkMYNffebMeta?.path ?? "/not-released/auth",
    meta: authlEkMYNffebMeta || {},
    alias: authlEkMYNffebMeta?.alias || [],
    redirect: authlEkMYNffebMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/not-released/auth.vue").then(m => m.default || m)
  },
  {
    name: gameHb4FK5vR1bMeta?.name ?? "not-released-game___en",
    path: gameHb4FK5vR1bMeta?.path ?? "/not-released/game",
    meta: gameHb4FK5vR1bMeta || {},
    alias: gameHb4FK5vR1bMeta?.alias || [],
    redirect: gameHb4FK5vR1bMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/not-released/game.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93VvncT2ddlPMeta?.name ?? "not-released-games-pageName___en",
    path: _91pageName_93VvncT2ddlPMeta?.path ?? "/not-released/games/:pageName()",
    meta: _91pageName_93VvncT2ddlPMeta || {},
    alias: _91pageName_93VvncT2ddlPMeta?.alias || [],
    redirect: _91pageName_93VvncT2ddlPMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/not-released/games/[pageName].vue").then(m => m.default || m)
  },
  {
    name: invite_45friends0HNUDjgjJ3Meta?.name ?? "not-released-invite-friends___en",
    path: invite_45friends0HNUDjgjJ3Meta?.path ?? "/not-released/invite-friends",
    meta: invite_45friends0HNUDjgjJ3Meta || {},
    alias: invite_45friends0HNUDjgjJ3Meta?.alias || [],
    redirect: invite_45friends0HNUDjgjJ3Meta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/not-released/invite-friends.vue").then(m => m.default || m)
  },
  {
    name: promotionsnGixWVbqHKMeta?.name ?? "not-released-promotions___en",
    path: promotionsnGixWVbqHKMeta?.path ?? "/not-released/promotions",
    meta: promotionsnGixWVbqHKMeta || {},
    alias: promotionsnGixWVbqHKMeta?.alias || [],
    redirect: promotionsnGixWVbqHKMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/not-released/promotions.vue").then(m => m.default || m)
  },
  {
    name: racesvICQKOK4WGMeta?.name ?? "not-released-races___en",
    path: racesvICQKOK4WGMeta?.path ?? "/not-released/races",
    meta: racesvICQKOK4WGMeta || {},
    alias: racesvICQKOK4WGMeta?.alias || [],
    redirect: racesvICQKOK4WGMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/not-released/races.vue").then(m => m.default || m)
  },
  {
    name: translations_45showcaseEHXl3GVovTMeta?.name ?? "not-released-translations-showcase___en",
    path: translations_45showcaseEHXl3GVovTMeta?.path ?? "/not-released/translations-showcase",
    meta: translations_45showcaseEHXl3GVovTMeta || {},
    alias: translations_45showcaseEHXl3GVovTMeta?.alias || [],
    redirect: translations_45showcaseEHXl3GVovTMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/not-released/translations-showcase.vue").then(m => m.default || m)
  },
  {
    name: _91name_93gjJu3cakn0Meta?.name ?? "page-name___en",
    path: _91name_93gjJu3cakn0Meta?.path ?? "/page/:name()",
    meta: _91name_93gjJu3cakn0Meta || {},
    alias: _91name_93gjJu3cakn0Meta?.alias || [],
    redirect: _91name_93gjJu3cakn0Meta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/page/[name].vue").then(m => m.default || m)
  },
  {
    name: promotionshF66CfpS2aMeta?.name ?? "promotions___en",
    path: promotionshF66CfpS2aMeta?.path ?? "/promotions",
    meta: promotionshF66CfpS2aMeta || {},
    alias: promotionshF66CfpS2aMeta?.alias || [],
    redirect: promotionshF66CfpS2aMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: referral_45infoas4dn6PE8VMeta?.name ?? "referral-info___en",
    path: referral_45infoas4dn6PE8VMeta?.path ?? "/referral-info",
    meta: referral_45infoas4dn6PE8VMeta || {},
    alias: referral_45infoas4dn6PE8VMeta?.alias || [],
    redirect: referral_45infoas4dn6PE8VMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/referral-info.vue").then(m => m.default || m)
  },
  {
    name: scratch_45cardst2VdAkSUPVMeta?.name ?? "scratch-cards___en",
    path: scratch_45cardst2VdAkSUPVMeta?.path ?? "/scratch-cards",
    meta: scratch_45cardst2VdAkSUPVMeta || {},
    alias: scratch_45cardst2VdAkSUPVMeta?.alias || [],
    redirect: scratch_45cardst2VdAkSUPVMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/scratch-cards.vue").then(m => m.default || m)
  },
  {
    name: wheel_45of_45treasurea4pK18TakvMeta?.name ?? "wheel-of-treasure___en",
    path: wheel_45of_45treasurea4pK18TakvMeta?.path ?? "/wheel-of-treasure",
    meta: wheel_45of_45treasurea4pK18TakvMeta || {},
    alias: wheel_45of_45treasurea4pK18TakvMeta?.alias || [],
    redirect: wheel_45of_45treasurea4pK18TakvMeta?.redirect || undefined,
    component: () => import("/app/apps/fortunewheelz/pages/wheel-of-treasure.vue").then(m => m.default || m)
  }
]