<script setup lang="ts">
import Popper from "vue3-popper-fixed";

import { HTMLProps } from "../types-utils";

type Instance = InstanceType<typeof Popper>;

defineProps<
	{
		placement?: Instance["$props"]["placement"];
		disableClickAway?: Instance["$props"]["disableClickAway"];
		offsetSkid?: Instance["$props"]["offsetSkid"];
		offsetDistance?: Instance["$props"]["offsetDistance"];
		hover?: Instance["$props"]["hover"];
		show?: Instance["$props"]["show"];
		disabled?: Instance["$props"]["disabled"];
		openDelay?: Instance["$props"]["openDelay"];
		closeDelay?: Instance["$props"]["closeDelay"];
		zIndex?: Instance["$props"]["zIndex"];
		arrow?: Instance["$props"]["arrow"];
		arrowPadding?: Instance["$props"]["arrowPadding"];
		interactive?: Instance["$props"]["interactive"];
		locked?: Instance["$props"]["locked"];
		content?: Instance["$props"]["content"];
	} & HTMLProps
>();

const instanceRef = ref<Instance>();

defineExpose({
	instanceRef
});
</script>

<template>
	<Popper
		ref="instanceRef"
		:placement="placement"
		:disableClickAway="disableClickAway"
		:offsetSkid="offsetSkid"
		:offsetDistance="offsetDistance"
		:hover="hover"
		:show="show"
		:disabled="disabled"
		:openDelay="openDelay"
		:closeDelay="closeDelay"
		:zIndex="zIndex"
		:arrow="arrow"
		:arrowPadding="arrowPadding"
		:interactive="interactive"
		:locked="locked"
		:content="content"
	>
		<slot />

		<template #content="props">
			<slot name="content" v-bind="props" />
		</template>
	</Popper>
</template>

<style>
:root {
	--a-popper-default-background-color: var(--cracow);
	--a-popper-default-background-color-hover: var(--cracow);
	--a-popper-default-text-color: var(--conakry);
	--a-popper-default-border-width: 0px;
	--a-popper-default-border-style: solid;
	--a-popper-default-border-radius: 5px;
	--a-popper-default-border-color: transparent;
	--a-popper-default-padding: 8px;
	--a-popper-default-box-shadow: none;
	--a-popper-default-font-weight: 400;

	--popper-theme-background-color: var(--a-popper-default-background-color);
	--popper-theme-background-color-hover: var(--a-popper-default-background-color-hover);
	--popper-theme-text-color: var(--a-popper-default-text-color);
	--popper-theme-border-width: var(--a-popper-default-border-width);
	--popper-theme-border-style: var(--a-popper-default-border-style);
	--popper-theme-border-radius: var(--a-popper-default-border-radius);
	--popper-theme-border-color: var(--a-popper-default-border-color);
	--popper-theme-padding: var(--a-popper-default-padding);
	--popper-theme-box-shadow: var(--a-popper-default-box-shadow);
}

.tao:root {
	--a-popper-default-background-color: var(--coro);
	--a-popper-default-text-color: var(--cannes);
}

.popper::-webkit-scrollbar {
	width: var(--scrollbar-width);
}
.popper::-webkit-scrollbar-thumb {
	width: var(--scrollbar-thumb-width);
	border-radius: 10px;
	background: var(--scrollbar-thumb-background);
}
.tao:root {
	--a-popper-default-background-color: var(--cheonan);
	--a-popper-default-background-color-hover: var(--cheonan);
	--a-popper-default-border-radius: 10px;
	--a-popper-default-padding: 10px;
	--a-popper-default-box-shadow: 0 4px 20px 0 var(--chandigarh);
}
.nlc:root {
	--a-popper-default-border-width: 1px;
	--a-popper-default-background-color: var(--chaozhou);
	--a-popper-default-background-color-hover: var(--chaozhou);
	--a-popper-default-border-radius: 10px;
	--a-popper-default-padding: 15px;
	--a-popper-default-box-shadow: 0px 4px 4px var(--chifeng);
	--a-popper-default-border-color: var(--caracas);
	--a-popper-default-border-radius: 10px;
}
.fortuneWheelz:root {
	--a-popper-default-background-color: var(--ceuta);
	--a-popper-default-background-color-hover: var(--ceuta);
	--a-popper-default-border-radius: 8px;
	--a-popper-default-padding: 12px;
	--a-popper-default-font-weight: 600;
}
</style>
<style scoped lang="scss">
:deep(.popper) {
	font-weight: var(--a-popper-default-font-weight);
	font-size: 14px;
	line-height: 16px;
	text-transform: none;
	max-width: calc(100vw - 40px);
}
</style>
