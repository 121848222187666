<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();

defineEmits<{ (event: "toggleSidebar"): void }>();
</script>

<template>
	<div class="mob-navigation">
		<div class="navigation-item" @click="$emit('toggleSidebar')">
			<ASvg name="navigation/menu" class="icon" />
			<AText variant="topeka" class="text">{{ t("Menu") }}</AText>
		</div>
		<div class="actions">
			<AButton variant="secondary" size="lg" class="login" @click="open('LazyOModalLogin')">
				<AText variant="toledo" :modifiers="['medium']">{{ t("Log In") }}</AText>
			</AButton>
			<AButton variant="primary" size="lg" class="signup" @click="open('LazyOModalSignup')">
				<AText variant="toledo" :modifiers="['medium']">{{ t("Sign Up") }}</AText>
			</AButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
.mob-navigation {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: gutter(1.25);
	padding: gutter(1) gutter(2);
	border-top: 1px solid var(--chandigarh);
	background: var(--craiova);
	z-index: 2;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	.actions {
		display: flex;
		gap: gutter(1);
	}

	.login {
		width: 88px;
	}

	.signup {
		width: 108px;
	}
}
.navigation-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40px;
	height: 40px;
	cursor: pointer;

	.text {
		color: var(--capiata);
	}

	.icon {
		font-size: 24px;
		color: var(--colon);
	}
}
</style>
