/* eslint-disable indent */
const useSupportChatInit = () => {
	const launch = ref(false);
	const launchAfterLoad = ref(() => {});
	const {
		public: { zendeskSupportChat }
	} = useRuntimeConfig();

	const { data } = useAppInitData();
	const { execute } = useAsyncFetch({
		path: "/chat/auth/",
		method: "post",
		fetchOptions: () => ({
			body: {
				login: data.value?.login,
				email: data.value?.email,
				appName: data.value?.appName
			},
			onResponse: ({ response }) => {
				if (response.status !== 200) {
					return;
				}
				zE("messenger", "loginUser", (send) => {
					send(response._data?.token || response._data || "");
				});
				setTimeout(() => {
					launchAfterLoad.value();
				}, 500);
			}
		}),
		options: {
			immediate: false
		}
	});

	const route = useRoute();

	const initAndOpen = () => {
		launch.value = true;
		zE("messenger", "open");
		launchAfterLoad.value = () => {
			zE("messenger", "open");
		};
	};

	const handleSupportButtonClick = () => {
		dispatchGAEvent({ event: "click_button", location: "widget", button_name: "support" });
		initAndOpen();
	};

	const handleSupportButtonMouseover = () => {
		launch.value = true;
	};
	const isHidden =
		route.path.includes("/cashIframe") || route.path.includes("/referral-info") || route.path.includes("/affiliates");
	const addScript = computed(() => launch.value && zendeskSupportChat && !isHidden);

	useEvent(["nuxt:open-chat-support"], () => {
		initAndOpen();
	});

	useHead(
		{
			script: () => [
				addScript.value
					? {
							src: zendeskSupportChat as string,
							id: "ze-snippet",
							defer: true,
							onLoad: async () => {
								launchAfterLoad.value();
								if (data.value?.isGuest === false) {
									await execute();
								}
							}
					  }
					: {}
			]
		},
		{ mode: "client" }
	);

	onMounted(() => {
		window.sessionStorage.removeItem("ZD-widgetOpen");
		if (route.query.openSupport !== undefined) {
			initAndOpen();
		}
	});

	return { handleSupportButtonClick, handleSupportButtonMouseover, enabled: !!zendeskSupportChat, initAndOpen };
};

export default useSupportChatInit;
