const useModal = <Modals, K extends keyof Modals = keyof Modals, P extends Modals[K] = Modals[K]>() => {
	const modals = useState("modals", () => ({
		items: new Map(),
		prevSize: 0,
		nextSize: 0
	}));

	const open = (name: K, props?: P) => {
		modals.value.prevSize = modals.value.items.size;
		modals.value.nextSize = modals.value.items.size + 1;
		modals.value.items.set(name, props);
		if (modals.value.items.size === 1) {
			document.body.style.overflow = "hidden";
		}
	};
	const close = (name: K) => {
		modals.value.prevSize = modals.value.items.size;
		modals.value.nextSize = modals.value.items.size - 1;
		modals.value.items.delete(name);
		if (modals.value.items.size === 0) {
			document.body.style.overflow = "";
		}
	};
	const isOpen = (name: K) => modals.value.items.has(name);

	const toggle = (name: K, props?: P) => {
		if (isOpen(name)) {
			return close(name);
		}
		return open(name, props);
	};

	return {
		modals,
		open,
		close,
		isOpen,
		toggle
	};
};

export default useModal;
