<script lang="ts" setup>
const { open } = useAppModals();
const { data } = useAppInitData({ immediate: false });
const loginGuard = useLoginGuard();
const valueProgress = computed(() => data.value?.season?.totalProgressPercents);
const currentLevel = computed(() => data.value?.season?.currentLevel || 1);

const prizeIcon = computed(() => {
	if (currentLevel.value !== 1 && currentLevel.value === 30) {
		return "/nuxt-img/seasons/leprechaun/tower-prize-gold.png";
	}
	return "/nuxt-img/seasons/leprechaun/tower-prize-base.png";
});

const handleOpenSeason = () => {
	loginGuard({
		success() {
			open("LazyOModalSeasonTower");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<div class="season-btn" @click="handleOpenSeason">
		<AText class="current-level" variant="toledo" :modifiers="['left', 'extrabold']" as="div">{{ currentLevel }}</AText>
		<NuxtImg
			:src="prizeIcon"
			class="prize-icon"
			format="webp"
			width="148"
			height="146"
			alt="prize icon"
			loading="lazy"
		/>
		<AProgressCircle
			class="season-progress"
			color="var(--chandigarh)"
			colorValue="var(--ciamis)"
			:value="valueProgress"
		/>
	</div>
</template>

<style lang="scss" scoped>
.season-btn {
	width: 56px;
	height: 56px;
	background: var(--cocio);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
	cursor: pointer;
}

.season-progress {
	position: absolute;
	top: -3px;
	left: -5px;
}

.prize-icon {
	position: absolute;
	top: 14px;
	left: 11px;
	z-index: 1;
	width: 36px;
	height: 36px;
}

.current-level {
	position: absolute;
	bottom: 11px;
	right: 48%;
	transform: translateX(50%);
	text-shadow: 2px 1px rgb(0 0 0 / 83%);
	color: var(--cannes);
	z-index: 2;
}
</style>
